import React                             from 'react';
import { MantineTheme, useMantineTheme } from '@mantine/core';
import { NextLink }                      from '@mantine/next';
import Image                             from 'next/image';


interface Props {
    route?: string;
    alt?: string;
    onClick?: () => void;
    dark?: string;
    light: string;
    width: number;
    height: number;
    className?: string;
}

export const CActionImage: React.FC<Props> = ({
                                                  route,
                                                  onClick,
                                                  dark,
                                                  light,
                                                  height,
                                                  width,
                                                  alt,
                                                  className
                                              }) => {

    const { colorScheme }: MantineTheme = useMantineTheme();

    return (

        <NextLink className={className} href={route || '#'} onClick={onClick}>
            <Image
                width={width}
                height={height}
                src={colorScheme === 'dark' ? dark : light}
                alt={alt}
            />

        </NextLink>
    );

};
