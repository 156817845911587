// THIS FILE IS LAYOUT DEPENDENT
// THE COMMENTED STUFF IS WHAT IS AVAILABLE TO CONFIGURE
export const AppThemeConfigs = {

    // Object of arrays with 10 colors
    colors: {
        // 'ocean-blue': ['#7AD1DD', '#5FCCDB', '#44CADC', '#2AC9DE', '#1AC2D9', '#11B7CD', '#09ADC3', '#0E99AC', '#128797', '#147885'],
        // 'bright-pink': ['#F0BBDD', '#ED9BCF', '#EC7CC3', '#ED5DB8', '#F13EAF', '#F71FA7', '#FF00A1', '#E00890', '#C50E82', '#AD1374'],
        // NOTE: This is our own oranges
        // 'orange': ['#FFCC80', '#FFB74D', '#FFA726', '#FFA726', '#FFCA28', '#F39C12', '#FF8F00', '#FFA000', '#FF8F00', '#FF6F00'],
        // Note: This is system oranges with our changes to 6-9
        'orange': ['#fff4e6', '#ffe8cc', '#ffd8a8', '#ffc078', '#ffa94d', '#ff922b', '#f39c12', '#ff8f00', '#f39c12', '#ff8f00'],
        brand: ['#fd823e', '#f39c12', '#474b54', '#4ec5a5', '#34558b', '#eff0f1'],
        primary: ['#ff5800'],
        secondary: ['#00A7F4'],
        success: ['#157347'],
        info: ['#373737'],
        warning: ['#ffca2c'],
        danger: ['#dc3545'],
        error: ['#dc3545'],

    },

    // White and black colors, defaults to '#fff' and '#000'
    white: '#fff',
    black: '#000',

    primaryColor: 'orange',

    // Controls focus ring styles:
    // auto – display focus ring only when user navigates with keyboard (default)
    // always – display focus ring when user navigates with keyboard and mouse
    // never – focus ring is always hidden (not recommended)
    focusRing: 'auto',

    // Determines whether motion based animations should be disabled for
    // users who prefer to reduce motion in their OS settings
    respectReducedMotion: false,

    // Determines whether elements that do not have pointer cursor by default
    // (checkboxes, radio, native select) should have it ['default' | 'pointer']
    cursorType: 'default',

    // Default border-radius used for most elements ['xs' | 'sm' | 'md' | 'lg' | 'xl' | number]
    defaultRadius: 'md',

    // Key of theme.colors
    // primaryColor: '#cc0f0f',
    // primaryColor: 'orange',

    // THIS WILL RESUlT IN ERROR, DO NOT DO THIS
    // primaryColor: '#EC45EE',

    // Index of color from theme.colors that is considered primary, Shade type is 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9
    // primaryShade: 1,
    // primaryShade: { light: 6, dark: 8 },

    // Default gradient used in components that support `variant="gradient"` (Button, ThemeIcon, etc.)
    // defaultGradient: { deg: 0, from: 'pink', to: 'orange' },
    defaultGradient: { from: 'orange', to: 'yellow', deg: 30 },

    // font-family and line-height used in most components
    fontFamily: 'Montserrat',
    // lineHeight: string | number,

    // Monospace font-family, used in Code, Kbd and Prism components
    fontFamilyMonospace: 'Monaco, Courier, monospace',

    // Timing function used for animations, defaults to 'ease'
    transitionTimingFunction: 'ease',

    // Sizes for corresponding properties ['xs' | 'sm' | 'md' | 'lg' | 'xl', number]
    fontSizes: {
        xs: 10,
        sm: 12,
    },
    radius: {
        xs: 6,
        sm: 8,
    },
    spacing: {
        xs: 4,
    },

    // Values used for box-shadow  ['xs' | 'sm' | 'md' | 'lg' | 'xl', string]
    shadows: {
        sm: 'rgba(0, 0, 0, 0.16) 0px 2px 4px',
    },

    // Breakpoints used in some components to add responsive styles
    breakpoints: {
        xxs: 400,
        xs: 500,
        sm: 800,
        md: 1000,
        lg: 1200,
        xl: 4000,
    },

    // h1-h6 styles, used in Title and TypographyStylesProvider components
    // properties for all headings
    headings: {
        fontFamily: 'Montserrat',
        fontWeight: 300,
        // properties for individual headings, all of them are optional
        sizes: {
            h1: { fontSize: 24, lineHeight: 1.5 },
            h2: { fontSize: 16, lineHeight: 2 },
            // ...up to h6
            // h6: {fontWeight: 900},
        },
    },

    // Default loader used in Loader and LoadingOverlay components ['oval' | 'bars' | 'dots',]
    loader: 'bars',

    // Add your own custom properties on Mantine theme
    other: {
        maxWidth: 1200,
        myCustomProp: 'ACCESS ME ANYWHERE WHERE YOU CAN ACCESS THEME OBJ',
    },
};
