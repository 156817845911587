import React        from 'react';
import { Text }     from '@mantine/core';
import { NextLink } from '@mantine/next';


interface Props {
    title: string;
    route?: string;
}

export const LinkButton: React.FC<Props> = ({ title, route }) =>
    <Text size="sm" variant="link" component={NextLink} href={route} title={title}>
        {title}
    </Text>
