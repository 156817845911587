import React, { useEffect }         from 'react';
import { Container, Loader, Paper } from '@mantine/core';
import { useSession }               from 'next-auth/react';
import { useRouter }                from 'next/router';
import { AppRoutes }                from '@core/router/AppRoutes';
import useAppData                   from '@hooks/use-app-data';


interface Props {
    children: React.ReactNode;
}

const AuthGuard: React.FC<React.PropsWithChildren<Props>> = ({ children }) => {

    //const [opened, setOpened] = useState(false);

    const router = useRouter();

    const {  setLastPage } = useAppData();

    const session = useSession()
    const isUser = !!session?.data?.user

    useEffect(() => {

        if (session?.status === 'loading') return

        // IF NOT AUTHENTICATED SEND HIM TO LOGIN
        // there is no user
        if (!isUser) {

            //setOpened(true);

            /*showNotification({
                title: 'Default notification',
                message: 'Nop',
            })*/

            /*setTimeout(
                () =>
                    openContextModal({
                        modal: 'authGuardModal',
                        innerProps: {},
                    }),
                1,
            );*/

            if(router.route !== AppRoutes.LOGIN) {
                // setLastRoute(router.route);
                setLastPage(router.asPath)
            }

            router.push(AppRoutes.LOGIN).catch(() => console.error('Unable to Route'))
        }

    }, [session, isUser, router]);

    /*if (loading || !hasUser) {
        return (
            <div>
                <Modal
                    centered={true}
                    opened={opened}
                    withCloseButton={false}
                    onClose={() => setOpened(false)}
                    title="Information"
                    overlayColor="#373737"
                    overlayOpacity={0.55}
                    overlayBlur={3}
                    transition="fade"
                    transitionDuration={300}
                    transitionTimingFunction="ease"
                    closeOnEscape={false}
                    closeOnClickOutside={false}
                >
                    BUTTON HERE TO SHOW LOGIN BUTTON WHEN CLICKED ON LOGIN OPEN ACTUAL LOGIN FORM
                </Modal>
            </div>
        );
    }*/

    if (isUser) {
        return children as unknown as JSX.Element;
    }

    return (
        <Container style={{
            margin: '0 auto',
            maxWidth: 1200,
            paddingLeft: 20,
            paddingRight: 20,
        }}>

            <Paper mt={30} style={{ textAlign: 'center' }}><Loader color="#f39c12" variant="dots" size="sm"/></Paper>

            {/*<Progress color="#f39c12" radius="xl" size="xl" value={100} striped={false} animate={false}/>*/}

        </Container>
    )

};

export default AuthGuard;
