/**
 * This is where we deal with the dxb stay pricing rules calculations
 * */
import {getWeeklyDates} from '@utils/date-utils';


export function mapBedRoomsText(bedroomsCount:number) {
    return bedroomsCount === 1 ? "Bedroom" : "Bedrooms"
}

//<editor-fold desc="CALCULATORS">
export const calculateTourismFees = (bedrooms, totalNights, fees, limit) => {

    let tFees = 0;

    const maxLimit = totalNights > limit ? limit : totalNights;

    // console.log(maxLimit, "maxLimit")

    tFees = bedrooms * fees * maxLimit;

    return tFees;
};

export const calculateWeeklyFees = (range: [Date, Date], fees: number, limit: number) => {

    // console.log(range, "range")

    const startDate = range[0]; //'2022-09-01';
    const endDate = range[1]; //'2022-09-30';
    const weeksCount = getWeeklyDates(startDate, endDate).length;

    // console.log(weeksCount, 'weeksCount')

    let tFees = 0;

    for (let i = 0; i < weeksCount; i++) {
        tFees -= fees;
    }

    return tFees;
};
//</editor-fold>

//<editor-fold desc="MAPPERS">
export const mapLimitRule = (totalNights, conditionValue, priceTypeName, amount, bedroomsCount) => {

    const limit = parseInt(conditionValue, 10);

    const maxLimit = totalNights > limit ? limit : totalNights

    const bedroomCount = bedroomsCount;

    const calculated = {
        typeName: priceTypeName,
        name: `AED ${amount} x ${bedroomCount} ${mapBedRoomsText(bedroomCount)} x ${maxLimit} Nights`,
        amount: calculateTourismFees(
            bedroomCount,
            totalNights,
            amount,
            limit
        )
    }

    // console.log(calculated, 'TOURISM FEE')

    return calculated;
}

export const mapEveryRule = (range: [Date, Date], conditionValue, priceTypeName, amount) => {

    // THIS IS SET TO WEEKLY
    // CAN BE SET TO MONTHLY BUT THERE IS NO CHECK YET
    // THIS WILL COUNT ALL THE WEEKS IN A SELECTED RANGE
    const calculated = {
        typeName: priceTypeName,
        name: `AED ${amount} x ${getWeeklyDates(range[0], range[1]).length} Weeks`,
        amount: calculateWeeklyFees(
            range,
            amount,
            parseInt(conditionValue, 10) // CURRENTLY NOT IN USE
        )
    }

    // console.log(calculated, 'WEEKLY DISCOUNT')

    return calculated;
}

// THIS RULE IS LOCKED TO SECURITY DEPOSIT
export const mapUntilRule = (priceTypeName, _amount) => {

    // THIS IS BASED ON - STRIPE PAYMENT CHECK.
    // SO IF THIS IS IN PLACE - AND USER HAVN'T YET
    // PROCESSED THE PAYMENT - IT WON'T GO WITH BOOKING

    const calculated = {
        typeName: priceTypeName,
        name: `${"This amount is refundable"}`,
        amount: _amount
    }

    return calculated;
}

export const mapNoneRule = (priceTypeName, _amount) => {

    const calculated = {
        typeName: priceTypeName,
        name: `${priceTypeName}`,
        amount: _amount
    }

    return calculated;
}
//</editor-fold>
