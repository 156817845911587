import { Button, Group, Text } from '@mantine/core';
import React                   from 'react';
import { ContextModalProps }   from '@mantine/modals';


export const AuthGuardModal = ({ context, id, innerProps }: ContextModalProps<{ modalBody: string }>) => {
    return (
        <Group position="center">
            <Text size="sm">You are not logged in please Login</Text>

            <Button
                variant="filled"
                gradient={{ from: 'indigo', to: 'cyan' }}
                onClick={() => {
                    context.closeModal(id);
                    // OPEN LOGIN FORM MODAL
                }}
            >
                Login
            </Button>
        </Group>
    );
};
