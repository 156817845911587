import { createStyles } from '@mantine/styles';


export const HEADER_HEIGHT = 100;

export default createStyles((theme: { shadows: any; other: any }) => ({

    root: {
        position: 'relative',
        zIndex: 100,
        // boxShadow: theme.shadows.sm,
        // marginBottom: 30,
        borderBottom: 0,
        // paddingLeft: 30,
        // paddingRight: 30,
    },

    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '100%',
        // paddingLeft: 20,
        // paddingRight: 20,
        maxWidth: theme.other.maxWidth,
    }

}));
