import React from 'react';
import { Container, Group, MantineTheme, Menu, Text, useMantineTheme } from '@mantine/core';
import { IconLockOpen, IconMenu2, IconUserPlus } from '@tabler/icons';
import { NextLink } from '@mantine/next';
import { AppRoutes } from '@core/router/AppRoutes';
import { UserAvatar } from '@layouts/Menu/User/UserAvatar';


export const AuthMenuButtons: React.FC = () => {
    const { colors }: MantineTheme = useMantineTheme();

    return (
        <Container px={0}>
            <Group>
                <Menu
                    width={150}
                    aria-label="main menu"
                    position="bottom-end"
                    transition="pop-top-right"
                    radius="xs"
                    // trigger="hover"
                    // onClose={() => setUserMenuOpened(false)}
                    // onOpen={() => setUserMenuOpened(true)}
                >
                    <Menu.Target>
                        <UserAvatar
                            icon={
                                <IconMenu2
                                    size={18}
                                    color={colors.gray[6]}
                                />
                            }
                        />
                    </Menu.Target>

                    <Menu.Dropdown>
                        <Menu.Item
                            p={10}
                            component={NextLink}
                            href={AppRoutes.LOGIN}
                            title="Log in"
                            icon={
                                <IconLockOpen
                                    size={20}
                                    color={colors.gray[6]}
                                    // stroke={1}
                                />
                            }
                        >
                            <Text ml={6} size="sm" weight={400}>Log in</Text>
                        </Menu.Item>

                        <Menu.Divider />

                        <Menu.Item
                            p={10}
                            component={NextLink}
                            href={AppRoutes.REGISTER}
                            title="Sign up"
                            icon={
                                <IconUserPlus
                                    size={20}
                                    color={colors.gray[6]}
                                    // stroke={1}
                                />
                            }
                        >
                            <Text ml={6} size="sm" weight={400}>Sign up</Text>
                        </Menu.Item>
                    </Menu.Dropdown>
                </Menu>
            </Group>
        </Container>
    );
};
