import dayjs            from 'dayjs';
import toObject         from 'dayjs/plugin/toObject';
import { intersection } from 'lodash';
import { empty }        from '@utils/index';


dayjs.extend(toObject);

export const getWeeklyDates = (startDate, endDate) => {

    const dateArray = [];

    let currentDate = dayjs(startDate);
    const stopDate = dayjs(endDate);

    while (currentDate <= stopDate) {
        dateArray.push(dayjs(currentDate).format('YYYY-MM-DD'));
        currentDate = dayjs(currentDate).add(1, 'weeks');
    }

    // console.log(dateArray, 'dateArray')

    return dateArray;
}

const MonthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

const defaultFormat = 'DD-MM-YYYY';

export const getYearMonthsFromCurrentMonth = () => {
    const datesArr = [];

    for (let i = 0; i < 12; i++) {
        const currentMonth = {
            id: i.toString(),
            monthName: MonthNames[dayjs().add(i, 'month').month()],
            year: dayjs().add(i, 'month').year(),
            dateObj: dayjs().add(i, 'month').format(defaultFormat).toString(),
        };

        datesArr.push(currentMonth);
    }

    return datesArr;
};

export const getFormattedMonthName = (months, template) => {
    const montNamesArr = [];

    months?.forEach((month) => {
        const format = dayjs(month?.dateObj, defaultFormat).format(template).toString();
        montNamesArr.push(format);
    });

    return montNamesArr.toString();
};

export const getBlockedDatesFromCalendar = (calendarData) => {
    // console.log(calendarData?.length, "calendarData")
    const blockedDates = [];

    calendarData?.forEach((item) => {
        // console.log(item?.blocked, "item?.blocked")

        if (item?.blocked === true || item?.available === false) {
            blockedDates.push(item?.date);
        }
    });

    return blockedDates;
};

export const disablePastDates = (current) => {
    return dayjs().add(-1, 'days') >= current;
};

export const disablePastAndControlledDates = (current, disabledDates) => {
    // const datesToDisable = ["2022-06-20", "2022-06-22", '2022-06-24'];

    return disablePastDates(current) || disabledDates.find((date) => date === dayjs(current).format('YYYY-MM-DD'));
};

export const matchDates = (dateString, dates) => {
    // THIS EXPECTS START AND END DATES
    //CONTEXT IS THAT - IF THE DATE IS MATCHED DON'T ADD

    // console.log(JSON.stringify(dateString, null, 2), "dateString")
    // console.log(JSON.stringify(dates, null, 2), "dates")
    // console.log("empty",empty(dateString[0]), empty(dateString[1]) )
    // console.log("MATCHES", intersection(dateString, dates)?.length !== 0)

    if (!empty(dateString[0]) && !empty(dateString[1])) {
        return intersection(dateString, dates)?.length !== 0;
    } else {
        return true;
    }
};

export const formatSearchDate = (date, placeHolder?: string) => {
    if (date !== null) {
        return dayjs(date, 'DD/MM/YYYY').format('YYYY-MM-DD').toString();
    } else {
        return placeHolder
    }
};

export const formatBookingCardRangeDate = (date, placeHolder?: string) => {
    if (date !== null) {
        return dayjs(date, 'YYYY-MM-DD').format('DD-MM-YYYY').toString();
    } else {
        return placeHolder
    }
};

export const formatSearchBarDateLabel = (date, placeHolder?: string) => {
    if (date !== null) {
        return dayjs(date, 'DD/MM/YYYY').format('DD-MM-YYYY').toString();
    } else {
        return placeHolder
    }
};

export const getDatesInRange = (range) => {
    if (range) {
        const startDate = range[0];
        const endDate = range[1];

        const dateArray = [];

        let currentDate = dayjs(startDate);
        const stopDate = dayjs(endDate);

        while (currentDate <= stopDate) {
            dateArray.push(dayjs(currentDate).format('YYYY-MM-DD'));
            currentDate = dayjs(currentDate).add(1, 'days');
        }

        return dateArray;
    } else {
        return [];
    }
};

export const extractDatesDataFromCalendarData = (rangeDates, calendarData) => {
    // console.log(calendarData, "extractDatesDataFromCalendarData")
    // console.log(intersection(rangeDates, calendarData), "extractDatesDataFromCalendar")

    const selectedDates = [];

    calendarData?.forEach((calendarDate) => {
        const localDate = rangeDates.find((local) => local === calendarDate?.date);

        if (localDate !== undefined) {
            selectedDates.push(calendarDate);
        }
    });

    // console.log(selectedDates, "selectedDates")

    return selectedDates;
};

export const getRangeDate = (dateString) => {
    if (!empty(dateString[0]) && !empty(dateString[1])) {
        return [
            dayjs(dateString[0]),
            dayjs(dateString[1])
        ];
    } else {
        return [null, null];
    }
};

/**
 * 'YYYY-MM-DD'
 * */
export const getFormattedDate = (dateString: string, format: string) => {
    return dayjs(dateString).format(format).toString();
}

export const checkIfRangeIsSelected = (dateString) => {
    return !empty(dateString[0]) && !empty(dateString[1]);
};

export const formatDate = (date) => {
    return dayjs(date, 'YYYY-MM-DD').format('DD-MM-YYYY').toString();

};
