import React from 'react';
import { Container, Header } from '@mantine/core';
import { AppLogo } from '@layouts/AppLogo';
import { AppMenu } from '@layouts/Menu/App/AppMenu';
import useStyles, { HEADER_HEIGHT } from './AppHeader.styles';
import { ColorSchemeButton } from "@layouts/ColorScheme/ColorSchemeButton";


export const AppHeader: React.FC = () => {
    const { classes } = useStyles();

    return (
        <Header
            height={HEADER_HEIGHT}
            className={classes.root}
        >
            <Container className={classes.header}>

                <AppLogo/>

                <AppMenu/>

                <ColorSchemeButton/>

            </Container>
        </Header>
    );
};
