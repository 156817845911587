import useNotification, { NotificationType }                   from '@hooks/useNotification';
import { ProfileStatusNotificationData }                       from '@hooks/notification-utils';
import { RecordStatus, useGetUserStatusLazyQuery, UserStatus } from '../../../generated/graphql';


// WHEN USER LOGIN!
// WHEN USER BOOKING!
// ALLOW TO CLOSE THE NOTIFICATION

export interface UserStatusResult {
    result?: boolean;
    status: boolean;
}

const useUserProfileStatus = () => {

    const { notify, showUserProfileStatusNotification } = useNotification();

    //<editor-fold desc="GET USER STATUS CALL">
    const [GetUserStatus] = useGetUserStatusLazyQuery({ fetchPolicy: 'network-only' });

    const fetchUserStatus = (_email: string): Promise<UserStatusResult> => {

        // if(!_email) return ;

        // SEND SERVER CALL
        return GetUserStatus({ variables: { email: _email } })
            .then(
                res => {
                    // console.log(res, "res")

                    const userStatus = res?.data?.getUserStatus as UserStatus;

                    // ONLY WHEN THE STATUS IS NOT APPROVED
                    if(userStatus.profile_status !== RecordStatus.PassportApproved) {
                        const notificationConfigs = ProfileStatusNotificationData[userStatus.profile_status];

                        showUserProfileStatusNotification(
                            notificationConfigs.title,
                            notificationConfigs.message,
                            notificationConfigs.color,
                            notificationConfigs.icon
                        )
                    }

                    return Promise.resolve({
                        result: userStatus.profile_status === RecordStatus.PassportApproved,
                        status: true
                    });
                }
            )
            .catch((err) => {
                console.error(err);

                notify(
                    NotificationType.ERROR,
                    `Oops we broke something!`,
                    2000
                );

                return Promise.resolve({
                    result: null,
                    status: false
                });
            });
    }

    //</editor-fold>


    return {
        fetchUserStatus
    }
}

export default useUserProfileStatus;
