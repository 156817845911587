import { useEffect }        from 'react';
import { useLocalStorage }  from '@mantine/hooks';
import { useSession }       from 'next-auth/react';
import { decodeToken }      from '@utils/network-utils';
import { UserProfile }      from '@features/account/data/user-types';
import useUserProfileStatus from '@features/common/hooks/use-user-profile-status';
import { AuthResult }       from '../../../pages/api/auth/[...nextauth]';


/**
 * SINGLE SOURCE OF TRUTH FOR USER INITIAL DATA
 * DONT MIX IT WITH PROFILE DATA
 * */
function useUserData() {

    const session = useSession();
    const { fetchUserStatus } = useUserProfileStatus();

    /**
     * Check if User is logged in and have active session.
     *
     * isUser();
     * */
    const isUser = (): boolean => {
        return !!session?.data?.user;
    }

    const getUserId = (): number => {

        if (isUser()) {
            // @ts-expect-error
            return decodeToken(session?.data?.user?.accessToken).userId
        } else {
            return -1;
        }

    }

    const getUserEmail = (): string => {

        if (isUser()) {
            return session?.data?.user.email
        } else {
            return null;
        }

    }

    // TODO: TYPINGS FOR PROP IDS
    const [userPropIds, _setUserPropIds] = useLocalStorage({
            key: 'user-prop-ids'
        }
    );

    const [userProfileData, _setUserProfileData] = useLocalStorage<UserProfile>({
        key: 'user-profile',
        defaultValue: {
            name: '__',
            email: 'no-reply@dxbstay.com',
            image: null,
            country: null,
            phone: null,
            profileStatus: null,
            accountStatus: null,
        }
    });

    useEffect(() => {

        if (isUser()) {

            const _user = session?.data?.user as AuthResult;

            // WHEN EVER THE USER LOGS IN IT WILL GET THE DATA
            _setUserProfileData({
                name: _user.name,
                image: _user.image,
                email: _user.email,
                profileStatus: _user.profile_status, //IF A PROFILE IS HAVE NOT COMPLETED
                accountStatus: _user.account_status, //IF AN ACCOUNT IS BANNED - PENDING VERIFICATIONS ETC
            });

        }

    }, [session]);

    const getUserProfileData = (): UserProfile => {
        return userProfileData;
    }

    const setUserProfileData = (data: UserProfile) => {
        _setUserProfileData(data);
    }

    const clearUserProfileData = () => {
        _setUserProfileData({
            name: '',
            email: '',
            image: null,
            userId: -1,
            profileStatus: null,
            accountStatus: null,
        });
    }

    const getUserPropIds = () => {
        return userPropIds;
    }

    const setUserPropIds = (data: any) => {
        _setUserPropIds(data);
    }

    const isPassportApproved = (): Promise<boolean> => {

        return fetchUserStatus(getUserEmail())
            .then((res) => {
                if (res.status) {
                    return Promise.resolve(res.result);
                } else {
                    return Promise.resolve(false);
                }

            })
            .catch((err) => {
                console.error(err)
                return Promise.resolve(false);
            })

    }


    const clearUserPropIds = () => {
        _setUserPropIds(null);
    }


    return {
        isUser,
        isPassportApproved,
        getUserId,
        getUserEmail,
        getUserPropIds,
        getUserProfileData,
        setUserProfileData,
        clearUserProfileData,
        clearUserPropIds,
        setUserPropIds,
    }
}

export default useUserData;
