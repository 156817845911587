import React from 'react';
import { Container, Group } from '@mantine/core';
import { AppMenuLinks } from '@core/router/AppRoutes';
import useStyles from '../MenuButtons.styles';

// USE IT WHEN YOU HAVE QUIET A FEW OF SAME TYPE OF BUTTONS
export const AppMenuButtons: React.FC = () => {
    const { classes, cx } = useStyles();

    // IF YOU WANT A BUTTON TO BE ACTIVE ON SELECTION
    // const [active, setActive] = useState("");

    const menuButtons = AppMenuLinks.map((route) => {
        return (
            <a
                key={route.label}
                href={route.link}
                title={route.label}
                aria-label={route.label}
                className={cx(classes.link)} //{[classes.linkActive]: active === route.link}
                /*onClick={(event) => {
                    event.preventDefault();
                    setActive(route.link);
                }}*/
            >
                {route.label}
            </a>
        );
    });

    return (
        <Container px={6}>
            <Group className={classes.group}>{menuButtons}</Group>
        </Container>
    );
};
