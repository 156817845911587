import { ReactNode } from "react";
import createSafeContext from "@hooks/useSafeContext";

export interface AppStateProps {
    counter?: number;
    // setImageSrc: (src: string | StaticImageData) => void;
}

export const APP_STATE_KEYS = {
    dummy: 'DUMMY'
}

export const [useAppState, Provider] = createSafeContext<AppStateProps>();

export const AppStateProvider = ({ children }: { children: ReactNode }) => {

    /*const [selectedImageIndex, setSelectedImageIndex] =
        useLocalStorage(APP_STATE_KEYS.count, '0', { sync: true });*/

    // const [dummyData, setDummyData] = useLocalStorage(APP_STATE_KEYS.test double, "", {sync: true});


    const providerValues: AppStateProps = {
        counter: 4146
    };

    return <Provider value={providerValues}>{children}</Provider>;
}
