import { cleanNotifications, showNotification } from '@mantine/notifications';
import { ReactNode }                            from 'react';


export enum NotificationType {
    INFO,
    ERROR,
    SUCCESS,
    FAIL,
    WARNING
}

interface NotificationConfigs {
    title: string;
    color: string;
    style: any;
}

function getConfigs(type: NotificationType): NotificationConfigs {

    let notificationConfigs: NotificationConfigs = {
        title: 'NO IDEA',
        color: 'red',
        style: (theme: { colors: { [x: string]: any[] }; white: any }) => ({
            root: {
                backgroundColor: theme.white,
            },

            title: { color: theme.colors.error[0] },
            description: { color: theme.colors.error[0] },
        })
    };

    switch (type) {
        case NotificationType.INFO:

            notificationConfigs = {
                title: 'Information',
                color: 'blue',
                style: (theme: { colors: { [x: string]: any[] }; white: any }) => ({
                    root: {
                        backgroundColor: theme.white,
                    },

                    title: { color: theme.colors.info[0] },
                    description: { color: theme.colors.info[0] },
                })
            };
            break;
        case NotificationType.ERROR:
            notificationConfigs = {
                title: 'Error',
                color: 'red',
                style: (theme: { colors: { [x: string]: any[] }; white: any }) => ({
                    root: {
                        backgroundColor: theme.white,
                    },

                    title: { color: theme.colors.error[0] },
                    description: { color: theme.colors.error[0] },
                })
            };
            break;
        case NotificationType.SUCCESS:
            notificationConfigs = {
                title: 'Success',
                color: 'green',
                style: (theme: { colors: { [x: string]: any[] }; white: any }) => ({
                    root: {
                        backgroundColor: theme.white,
                    },

                    title: { color: theme.colors.success[0] },
                    description: { color: theme.colors.success[0] },
                })
            };
            break;
        case NotificationType.FAIL:
            notificationConfigs = {
                title: 'Failed',
                color: 'orange',
                style: (theme: { colors: { [x: string]: any[] }; white: any }) => ({
                    root: {
                        backgroundColor: theme.white,
                    },

                    title: { color: theme.colors.error[0] },
                    description: { color: theme.colors.error[0] },
                })
            };
            break;
        case NotificationType.WARNING:
            notificationConfigs = {
                title: 'Warning',
                color: 'orange',
                style: (theme: { colors: { [x: string]: any[] }; white: any }) => ({
                    root: {
                        backgroundColor: theme.white,
                    },

                    title: { color: theme.colors.warning[0] },
                    description: { color: theme.colors.warning[0] },
                })
            };
            break;
        default:
    }

    return notificationConfigs;
}

function useNotification() {

    function notify(
        type: NotificationType,
        message: string | ReactNode,
        autoClose?: boolean | number,
        onClose?: () => void
    ): void {

        showNotification({
            title: getConfigs(type).title,
            message,
            autoClose,
            color: getConfigs(type).color,
            //styles: getConfigs(type).style,
            onClose: (props) => {
                if (onClose) {
                    onClose();
                }
            }
        });
    }

    function showUserProfileStatusNotification(
        _title: string,
        message: string | ReactNode,
        _color?: string,
        _icon?: ReactNode,
        onClose?: () => void
    ) {

        cleanNotifications();

        showNotification({
            id: 'profileStatusNotify',
            title: _title,
            message,
            autoClose: false,
            disallowClose: false,
            icon: _icon,
            color: _color,
            //styles: _style,
            onClose: (props) => {
                if (onClose) {
                    onClose();
                }
            }
        });

    }

    function showLoginErrorNotification(_message:string) {

        showNotification({
            title: 'Error',
            message: _message,
            autoClose: 2000,
            styles: (theme: { colors: { [x: string]: any[] }; white: any }) => ({
                root: {
                    backgroundColor: theme.white,
                },

                title: { color: theme.colors.error[0] },
                description: { color: theme.colors.error[0] },
            }),
        });
    }


    return { notify, showUserProfileStatusNotification, showLoginErrorNotification };
}

export default useNotification;
