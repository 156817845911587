import slugify from 'slugify';


export const AppRoutes = {
    NOT_FOUND: '/not-found',

    HOME: '/',
    CONTACT: '/contact-us',
    ACCOUNT: '/account',
    FAVORITES: '/favorites',
    ABOUT: '/about-us',
    BOOK: '/book',
    BOOKINGS: '/bookings',
    CHANGE_PASSWORD: '/reset-password',
    FORGET_PASSWORD: '/forget-password',
    PROPERTIES: '/properties',
    SEARCH: '/search',
    REGISTER: '/register',
    LOGIN: '/login',
    TERMS: '/terms',
    POLICIES: '/policies',
    PROPERTY_DETAILS: '/property-details',
    RESPONSE: '/response',
};

export const AppMenuLinks = [
    { link: AppRoutes.PROPERTIES, label: 'Properties' },
    // {link: AppRoutes.ABOUT, label: "About Us"},
    // {link: AppRoutes.CONTACT, label: "Contact Us"},
];

export const RESTRICTED_PATHS = ['/account'];
export const AUTH_PATHS = [
    AppRoutes.LOGIN,
    AppRoutes.REGISTER,
    AppRoutes.CHANGE_PASSWORD,
    AppRoutes.FORGET_PASSWORD,
];

export const getPageRoute = (currentLocation: string, id: string) => {
    return `${currentLocation}/${id}`;
};

export const getPageSlug = (title: string, location?: string) => {
    // return `${location?.city}/${location?.address}/${title}`

    return slugify(`${title}`, { lower: true });
};

export const getSlugRoute = (route: string, slug: string, location?: string) => {
    // return getPageRoute(route, getPageSlug(slug))
    return getPageSlug(slug);
};
