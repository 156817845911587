import Router        from 'next/router';
import { isBrowser } from '@utils/index';


export const convertStrToSnakeCase = (str: string) => {
    return str?.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
              .map(s => s.toLowerCase())
              .join('-');
}

export const toCapitalize = (name: string) => {
    const [first, ...rest] = name;
    return `${first.toUpperCase()}${rest.join('')}`;
};

export const validateEmail = (value: string) => {
    return /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/.test(value);
};

export function removeFromLast<T>(path: string, key: string): string | T {
    const i = path.lastIndexOf(key);
    return i === -1 ? path : path.substring(0, i);
}

export function isFunction(fn: Function): boolean {
    return typeof fn === 'function';
}

export const isPathActive = (href: string, exact = false): boolean => {
    if (!isBrowser) return false;
    if (exact) return Router.pathname === href;
    return Router.pathname.startsWith(href);
};

export const isDefined = <T>(t: T | null | undefined): t is T => t != null;

export const isString = (value: unknown): value is string => {
    return typeof value === 'string';
};

export const isRegex = (value: unknown): value is RegExp => {
    return value instanceof RegExp;
};

export const isDateObject = (value: unknown): value is Date => {
    return value instanceof Date;
};

export const isObject = (value: unknown): value is Record<string, any> => {
    return Boolean(value) && !Array.isArray(value) && typeof value === 'object' && !isRegex(value) && !isDateObject(value);
};

/**
 * Converts a string or number to a boolean.
 *
 * @example
 *      isTruthy("FALSE");
 *      // false
 *
 *      isTruthy("off");
 *      // false
 *
 *      isTruthy(0);
 *      // false
 *
 *      isTruthy(undefined);
 *      // false
 *
 * ts-prune-ignore-next
 */
export const isTruthy = (value: unknown): value is boolean => {
    const normalized = typeof value === 'string' ? value.toLowerCase() : value;

    return !(normalized == null || (normalized as number) <= 0 || normalized === 'false' || normalized === 'off');
};

export const noop = Function.prototype as <T>(...params: T[]) => T;

export const uniq = <T>(array: T[]): T[] => Array.from(new Set(array));

export function getMultipleRandom(arr, num) {
    const shuffled = [...arr].sort(() => 0.5 - Math.random());

    return shuffled.slice(0, num);
}
