import React, { useEffect, useState }                        from 'react';
import { useRouter }                                         from 'next/router';
import { useHotkeys, useLocalStorage }                       from '@mantine/hooks';
import { ColorScheme, ColorSchemeProvider, MantineProvider } from '@mantine/core';
import { ModalsProvider }                                    from '@mantine/modals';
import { NotificationsProvider }                             from '@mantine/notifications';
import { AUTH_PATHS, RESTRICTED_PATHS }                      from '@core/router/AppRoutes';
import { AppThemeConfigs }                                   from '@core/config/AppThemeConfigs';
import { DirectionContext }                                  from '@core/config/DirectionContext';
import { rtlCache }                                          from '@core/config/rtl-cache';
import AuthGuard                                             from '@core/layouts/Functional/AuthGuard';
import { AppStateProvider }                                  from "@core/providers/AppStateProvider";
import { PropertyStateProvider }                             from "@core/providers/PropertyStateProvider";
import { BookingStateProvider }                              from "@core/providers/BookingStateProvider";
import { AppHeader }                                         from '@layouts/Header/AppHeader';
import { AppFooter }                                         from '@layouts/Footer/AppFooter';
import { AuthGuardModal }                                    from '@features/auth/auth-gaurd-modal/AuthGuardModal';
import useAppData                                            from '@hooks/use-app-data';


// TODO:: REFACTOR HIERARCHY STRUCTURE

interface Props {
    children: React.ReactNode;
    noHeader?: boolean;
    requireAuth: boolean;
}

const THEME_KEY = 'dxb-color-scheme';
type Direction = 'rtl' | 'ltr'

const AppLayout: React.FC<React.PropsWithChildren<Props>> = ({
                                                                 children,
                                                                 requireAuth = false,
                                                                 noHeader = false
                                                             }) => {

    //<editor-fold desc="ROUTING">
    const { setLastRoute } = useAppData();

    const router = useRouter();
    const restrictedPath = RESTRICTED_PATHS.some((path) => router.route.startsWith(path));
    const authPath = AUTH_PATHS.some((path) => router.route.startsWith(path));

    useEffect(() => {

        if (!authPath) {
            setLastRoute(router.route);
        }

    }, [authPath]);
    //</editor-fold>

    //<editor-fold desc="THEME">
    const [dir, setDir] = useState<Direction>('ltr');
    const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
        key: THEME_KEY,
        defaultValue: 'light',
        getInitialValueInEffect: true,
    });

    const toggleColorScheme = (value?: ColorScheme) => setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));

    useHotkeys([['ctrl+J', () => toggleColorScheme()]]);
    //</editor-fold>

    //<editor-fold desc="RENDER">
    return (
        <>
            {/*<BackToTop/>*/}

            <DirectionContext.Provider value={dir}>
                <ColorSchemeProvider
                    colorScheme={colorScheme}
                    toggleColorScheme={toggleColorScheme}
                >
                    <MantineProvider
                        // @ts-expect-error
                        theme={{
                            ...AppThemeConfigs,
                            colorScheme,
                            dir,
                        }}
                        withGlobalStyles={true}
                        withNormalizeCSS={true}
                        emotionCache={dir === 'rtl' ? rtlCache : undefined}
                    >
                        <NotificationsProvider position="top-right" zIndex={2077}>
                            <ModalsProvider
                                modals={{
                                    authGuardModal: AuthGuardModal,
                                }}
                            >
                                <AppStateProvider>
                                    <PropertyStateProvider>
                                        <BookingStateProvider>

                                            {!noHeader && <AppHeader/>}

                                            {requireAuth || restrictedPath ? (
                                                <AuthGuard>
                                                    <main style={{ minHeight: 'calc(90vh - 100px)' }}>{children}</main>
                                                </AuthGuard>
                                            ) : (
                                                <main style={{ minHeight: 'calc(90vh - 100px)' }}>{children}</main>
                                            )}

                                            {!noHeader && <AppFooter/>}

                                        </BookingStateProvider>
                                    </PropertyStateProvider>
                                </AppStateProvider>
                            </ModalsProvider>
                        </NotificationsProvider>
                    </MantineProvider>
                </ColorSchemeProvider>
            </DirectionContext.Provider>
        </>

        /*<>
            APP LAYOUT
            <div>{children}</div>
        </>*/
    );
    //</editor-fold>

};

export default AppLayout;
