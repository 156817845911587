import React from 'react';
import { AppMetaData } from '@core/constants/app-meta-data';
import { AppSeo } from "@core/seo/AppSeo";
import { OpenGraph } from "next-seo/lib/types";


interface Props {
    title?: string;
    description?: string;
    openGraph?: OpenGraph;
}

const AppHead: React.FC<Props> = ({ title, description , openGraph }) => {

    const seoTitle = (title ? `${title} | ` : '') + AppMetaData.APP_NAME;

    return (
        <AppSeo
            title={seoTitle}
            description={description}
            openGraph={openGraph || null}
        />
    );
};

export default AppHead;
