import { ReactNode } from "react";
import createSafeContext from "@hooks/useSafeContext";
import { useLocalStorage } from "@hooks/useLocalStorage";
import { Price, PropertyPrices, useCreateBookingPaymentSessionMutation } from "../../generated/graphql";
import useNotification, { NotificationType } from "@hooks/useNotification";
import useUserData from "@features/common/hooks/use-user-data";
import { extractDatesDataFromCalendarData, getDatesInRange, getFormattedDate } from "@utils/date-utils";
import { ERROR, FAILED, SUCCESS } from "@core/constants/app-consts";
import { usePropertyState } from "@core/providers/PropertyStateProvider";
import { getPricePerNight, getTotalNights, getTotalPrice } from "@utils/dxb-utils";
import { mapEveryRule, mapLimitRule, mapNoneRule, mapUntilRule } from "@core/providers/price-rule-mappers";

//FOR PRICE SUMMERY
interface PropertyPriceCalculation {
    totalPrice: number;
    dxbTotalPrice: number;
    grandTotalPrice: number;
    totalNights: number;
    pricePerNight: number;
}

const initialPriceCalculation = {
    totalPrice: 0,
    dxbTotalPrice: 0,
    grandTotalPrice: 0,
    totalNights: 0,
    pricePerNight: 0,
};

export interface BookingInfo {
    user_id?: string;
    // smoobu_id?: string;
    property_id?: string;
    guests?: number;
    start_date?: string;
    end_date?: string;

    // dxb_stay_price_ids?: string;
    // grand_total_price?: number;
    // price_per_night?: number;
    // total_nights?: number;
    // total_price?: number;
    // status: string;
}

const initialBookingInfo = {
    property_id: '-1',
    user_id: '-1',
    // smoobu_id: '-1',
    guests: -1,
    start_date: '',
    end_date: '',
    // dxb_stay_price_ids: '',
    // price_per_night: 0,
    // grand_total_price: 0,
    // total_nights: 0,
    // total_price: 0,
    // status: 'disabled'
};

interface BookingCallResult {
    url: string;
    status: boolean;
}

export interface BookingStateProps {

    priceCalculations?: PropertyPriceCalculation;
    setPriceCalculations?: (calculations: PropertyPriceCalculation) => void;
    propertyCalendarPrices?: PropertyPrices;
    setPropertyCalendarPrices?: (propertyPrices: any) => void;

    // FOR STATE
    selectedDates?: any;
    setSelectedDates?: (dates: Date[]) => void;

    // FOR DATA
    selectedBookingRange?: Date[];
    setSelectedBookingRange?: (dates: Date[]) => void;

    guestsCount?: number;
    setGuestsCount?: (count: number) => void;

    dxbPricesCalculatedByRules?: any;
    setDxbPricesCalculatedByRules?: (value: any) => void;

    resetBookingInfo?: () => void;

    bookingInfo?: BookingInfo;
    setBookingInfo?: (record: any) => void;

    isReadyToBook: () => boolean;

    createBookingPaymentSession: () => Promise<BookingCallResult>;

    getGuestLimit: () => any;

    calculatePricesAndStore: (dateRange: [Date, Date]) => void;
    resetPrices: () => void;
}

export const BOOKING_STATE_KEYS = {
    BOOKING_GUESTS_COUNT: 'BOOKING_GUESTS_COUNT',
    PRICE_CALCULATIONS: 'P_CALC',
    PROPERTY_CALENDAR_PRICES: 'P_CAL_CALC',
    SELECTED_DATES: 'SELECTED_DATES',
    SELECTED_BOOKING_RANGE: 'SELECTED_BOOKING_RANGE',
    DXB_PRICE_CALCULATED_BY_RULES: 'DXB_P_CALC_R',
    BOOKING_INFO: 'BOOKING_INFO',
}

export const [useBookingState, Provider] = createSafeContext<BookingStateProps>();

export const BookingStateProvider = ({ children }: { children: ReactNode }) => {

    const { notify } = useNotification();

    const { getUserId } = useUserData();
    const { propertyDetailsData } = usePropertyState()

    const [createBookingPaymentSessionMutation] = useCreateBookingPaymentSessionMutation()


    //<editor-fold desc="PRICE CALCULATIONS">
    const [priceCalculations, setPriceCalculations] =
        useLocalStorage(BOOKING_STATE_KEYS.PRICE_CALCULATIONS, initialPriceCalculation, { sync: true });


    //</editor-fold>

    //<editor-fold desc="PROPERTY CALENDAR PRICES">
    const initialPropertyCalendarPrices = {
        dxbstay: null, smoobu: null
    };

    const [propertyCalendarPrices, setPropertyCalendarPrices] =
        useLocalStorage(BOOKING_STATE_KEYS.PROPERTY_CALENDAR_PRICES, initialPropertyCalendarPrices, { sync: true });


    //</editor-fold>

    //<editor-fold desc="BOOKING RANGE SELECTION">

    // FOR STATE
    const [selectedDates, setSelectedDates] =
        useLocalStorage(BOOKING_STATE_KEYS.SELECTED_DATES, [null, null], { sync: true });

    // FOR DATA
    const [selectedBookingRange, setSelectedBookingRange] =
        useLocalStorage(BOOKING_STATE_KEYS.SELECTED_BOOKING_RANGE, null, { sync: true });

    //</editor-fold>

    //<editor-fold desc="GUEST COUNT">

    const [guestsCount, setGuestsCount] =
        useLocalStorage(BOOKING_STATE_KEYS.BOOKING_GUESTS_COUNT, -1, { sync: true });

    //</editor-fold>

    //<editor-fold desc="SELECTED - PROPERTY - DXB PRICES CALCULATED BY RULES">

    const [dxbPricesCalculatedByRules, setDxbPricesCalculatedByRules] =
        useLocalStorage(BOOKING_STATE_KEYS.DXB_PRICE_CALCULATED_BY_RULES, [], { sync: true });


    //</editor-fold>

    //<editor-fold desc="BOOKING INFO">

    const [bookingInfo, setBookingInfo] =
        useLocalStorage(BOOKING_STATE_KEYS.BOOKING_INFO, initialBookingInfo, { sync: true });

    const resetBookingInfo = () => {

        setBookingInfo(null);
        setSelectedDates([null, null]);
        setGuestsCount(-1);
        setSelectedBookingRange([null, null]);
        setDxbPricesCalculatedByRules([]);

    }

    //</editor-fold>

    //<editor-fold desc="UTILS">

    const isReadyToBook = (): boolean => {

        if (selectedBookingRange && guestsCount) {
            return (selectedBookingRange[0] !== null) && (guestsCount !== -1);
        }else{
            return false;
        }

    }

    //</editor-fold>

    //<editor-fold desc="BOOKING CALLS">
    const createBookingPaymentSession = (): Promise<BookingCallResult> => {

        const record = {
            user_id: getUserId().toString(),
            property_id: propertyDetailsData.id.toString(),
            guests: guestsCount || -1,

            // 2022-07-25
            // 25-07-2022
            start_date: getFormattedDate(selectedBookingRange[0], 'YYYY-MM-DD'),
            end_date: getFormattedDate(selectedBookingRange[1], 'YYYY-MM-DD'),
        }

        setBookingInfo(record);

        // SEND SERVER CALL
        return createBookingPaymentSessionMutation({ variables: { input: record } })
            .then(
                result => {
                    // console.log('result>> ', result);

                    let _status = false;

                    if (result?.data?.createBookingPaymentSession.status === SUCCESS) {
                        notify(
                            NotificationType.SUCCESS,
                            `Please wait we are redirecting you to payment page..`,
                            2000
                        );

                        _status = true;
                    }

                    if (result?.data?.createBookingPaymentSession.status === FAILED) {
                        // console.log('RESULT FAILED >>', result);

                        notify(
                            NotificationType.WARNING,
                            `${result?.data?.createBookingPaymentSession.data}`,
                            2000
                        );

                        _status = false;
                    }

                    if (result?.data?.createBookingPaymentSession.status === ERROR) {
                        // console.log('RESULT ERROR >>', result);

                        if (result?.data?.createBookingPaymentSession.data === null) {

                            const parsedResp = JSON.parse(result?.data?.createBookingPaymentSession.error)
                            const errorMessage = JSON.parse(parsedResp.details.verbose)

                            notify(
                                NotificationType.WARNING,
                                `${errorMessage.detail}`,
                                2000
                            );

                        } else {
                            notify(
                                NotificationType.WARNING,
                                `${result?.data?.createBookingPaymentSession.data}`,
                                2000
                            );
                        }

                        _status = false;
                    }

                    return Promise.resolve({
                        url: result?.data?.createBookingPaymentSession.data,
                        status: _status
                    });
                }
            )
            .catch((err) => {
                console.error(err);
                notify(
                    NotificationType.ERROR,
                    `Oops we broke something!`,
                    2000
                );

                return Promise.resolve({
                    url: '',
                    status: false
                });
            });

    }

    //</editor-fold>

    //<editor-fold desc="GUEST COUNTER INTERNALS">
    const getGuestLimit = () => {

        const arr = Array(propertyDetailsData.details?.max_occupancy)
            .fill(0)
            .map((_, ind) => ({ count: ind + 1 }));

        return arr;
    }
    //</editor-fold>

    //<editor-fold desc="PRICE CALCULATIONS RELATED">

    const executeDXBPricingRules = (totalNights: number, range: [Date, Date]): Price[] => {

        const data = propertyCalendarPrices.dxbstay;

        const result = [];

        data?.forEach((priceItem) => {

            const isConditional = priceItem.priceType.conditional;

            if (isConditional) {

                const condition = priceItem.priceType.condition.split('|')[0];
                const conditionValue = priceItem.priceType.condition.split('|')[1];
                // const conditionSubValue = priceItem.priceType.condition.split('|')[2];

                if (condition === 'LIMIT') {

                    const calculated = mapLimitRule(totalNights,
                        conditionValue,
                        priceItem.priceType.name,
                        priceItem.amount,
                        propertyDetailsData.details.bed_rooms
                    );

                    result.push(calculated);
                }

                if (condition === 'EVERY') {

                    const calculated = mapEveryRule(range,
                        conditionValue,
                        priceItem.priceType.name,
                        priceItem.amount
                    );

                    result.push(calculated);
                }

                if (condition === 'UNTIL') {

                    const calculated = mapUntilRule(priceItem.priceType.name, priceItem.amount);

                    result.push(calculated);
                }

            } else {

                const conditionTitle = priceItem.priceType.condition;

                if (conditionTitle === 'NONE') {

                    const calculated = mapNoneRule(priceItem.priceType.name, priceItem.amount);

                    result.push(calculated);

                }

            }
        });

        return result;
    };

    const calculatePricesAndStore = (dateRange: [Date, Date]) => {

        const data = propertyCalendarPrices;

        // console.log(dateRange, 'dateRange')
        // console.log(data, 'data')

        if (dateRange && data) {
            //RUN CALCULATIONS ON EACH TIME RANGE SELECTION IS DONE
            const datesInRang = getDatesInRange(dateRange);
            const calendarData = extractDatesDataFromCalendarData(datesInRang, data.smoobu)

            // REDUCING 1 DAY
            calendarData.pop();

            // IF THERE IS CALENDAR DATA DO STUFF
            if (calendarData.length) {
                const _totalNights = getTotalNights(calendarData)
                const _smoobuTotalPrice = getTotalPrice(calendarData)
                const _pricePerNight = getPricePerNight(_totalNights, _smoobuTotalPrice)

                // TOTAL CALCULATIONS
                const dxbRuleCalculatedTotal = executeDXBPricingRules(_totalNights, dateRange)

                // const _dxbTotalPrice = getDxbTotal(data.dxbstay);
                // const _dxbTotalPrice = getDxbTotal(dxbRuleCalculatedTotal);
                // const _grandTotal = getGrandTotal(_smoobuTotalPrice, _dxbTotalPrice)
                const _dxbTotalPrice = dxbRuleCalculatedTotal.reduce((acc: number, item) => acc + item.amount, 0);
                const _grandTotal = _smoobuTotalPrice + _dxbTotalPrice;

                // console.log(datesInRang, "datesInRang")
                // console.log(calendarData, "calendarData")
                // console.log(_totalNights, "totalNights")
                // console.log(_smoobuTotalPrice, "smoobuTotalPrice")
                // console.log(_pricePerNight, "pricePerNight")
                // console.log(_dxbTotalPrice, 'dxbTotalPrice')
                // console.log(_grandTotal, "grandTotal")

                setDxbPricesCalculatedByRules(dxbRuleCalculatedTotal);

                setPriceCalculations({
                    dxbTotalPrice: _dxbTotalPrice,
                    grandTotalPrice: _grandTotal,
                    pricePerNight: _pricePerNight,
                    totalNights: _totalNights,
                    totalPrice: _smoobuTotalPrice
                })
            }
        }

    }

    const resetPrices = () => {

        setPriceCalculations({
            dxbTotalPrice: 0,
            grandTotalPrice: 0,
            pricePerNight: 0,
            totalNights: 0,
            totalPrice: 0
        });

        setDxbPricesCalculatedByRules([]);

        setSelectedBookingRange([null, null]);
    }

    //</editor-fold>




    const providerValues: BookingStateProps = {
        priceCalculations, setPriceCalculations,
        propertyCalendarPrices, setPropertyCalendarPrices,
        selectedDates, setSelectedDates,
        selectedBookingRange, setSelectedBookingRange,
        guestsCount, setGuestsCount,
        dxbPricesCalculatedByRules, setDxbPricesCalculatedByRules,
        bookingInfo, resetBookingInfo,
        isReadyToBook,
        createBookingPaymentSession,
        getGuestLimit,
        calculatePricesAndStore, resetPrices,
    };

    return <Provider value={providerValues}>{children}</Provider>;
}
