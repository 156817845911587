import React, { ReactNode }              from 'react';
import { MantineTheme, useMantineTheme } from '@mantine/core';
import { NextLink }                      from '@mantine/next';


interface Props {
    route?: string;
    onClick?: () => void;
    dark?: ReactNode;
    light: ReactNode;
    target?: string;
    title?: string;
}

export const CActionIcon: React.FC<Props> = ({
                                                 route,
                                                 onClick,
                                                 dark,
                                                 light,
                                                 target,
                                                 title,
                                             }) => {

    const { colorScheme }: MantineTheme = useMantineTheme();

    return (

        <NextLink title={title} target={target} href={route || '#'} onClick={onClick}>
            {colorScheme === 'dark' ? dark : light}
        </NextLink>
    );

};
