import React, { forwardRef }                   from 'react';
import { Avatar, Group, Text, UnstyledButton } from '@mantine/core';
import { IconChevronRight }                    from '@tabler/icons';
import { displayAvatarName }                   from '@utils/dxb-utils';


const styles = (
    theme: {
        colors: { [x: string]: any[] };
        colorScheme: string;
        fn: any;
        fontSizes: any;
        spacing: any;
        shadows: any;
        radius: any;
        white: any;
    }) => ({
    display: 'block',
    // width: '100%',
    // padding: 2,
    paddingRight: 8,
    borderRadius: theme.radius.sm,
    borderWidth: 1,
    borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2],
    borderStyle: 'solid',
    color: theme.colorScheme === 'dark' ? theme.white : theme.colors.dark[9],
    backgroundColor:
        theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,

    '&:hover': {
        backgroundColor:
            theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
        boxShadow: theme.shadows.sm,
        borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2],
        transition: 'box-shadow 0.1s ease-in',
    }

});

export const UserAvatar = forwardRef(({ name, email, image, icon, theme, ...others }: any, ref) => {
    return (
        <UnstyledButton ref={ref} sx={styles} {...others}>
            <Group spacing="xs">

                <Avatar size={32} src={image} radius="sm"/>

                <div style={{ flex: 1 }}>
                    <Text size="sm" weight={400}>
                        {displayAvatarName(name)}
                    </Text>

                    {/*<Text color="dimmed" size="xs">
                        {email}
                    </Text>*/}
                </div>

                {icon || <IconChevronRight color={theme.colorScheme === 'dark' ? theme.colors.gray[0] : theme.colors.dark[9]} stroke={1} size={18}/>}
            </Group>
        </UnstyledButton>
    );

    /*return (
        <UnstyledButton ref={ref} sx={styles} {...others}>
            <Group spacing={7}>
                <Avatar src={image} alt={name} radius="xl" size={20}/>

                <Text weight={500} size="sm" sx={{ lineHeight: 1 }} mr={3}>
                    {name}
                </Text>

                <IconChevronDown size={12} stroke={1.5}/>
            </Group>
        </UnstyledButton>
    )*/
});
