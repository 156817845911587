import React from 'react';
import { Container, Group, MantineTheme, Menu, Text, useMantineTheme } from '@mantine/core';
import { NextLink } from '@mantine/next';
import { IconHeart, IconLock, IconLogout, IconMenu2, IconSettings, IconStar, IconUser } from '@tabler/icons';
import { AppRoutes } from '@core/router/AppRoutes';
import { UserAvatar } from '@layouts/Menu/User/UserAvatar';
import { LogoutButton } from '@layouts/Menu/LogoutButton';
import { displayAvatarName } from '@utils/dxb-utils';
import { generateKey, S3_USER_BUCKET_URL } from '@utils/network-utils';
import useUserData from '@features/common/hooks/use-user-data';


export const UserMenuButtons: React.FC = () => {

    const { colors }: MantineTheme = useMantineTheme();

    const { getUserProfileData } = useUserData();

    const { image, name, email } = getUserProfileData();

    return (
        <Container px={0}>
            <Group>
                <Menu
                    width={250}
                    aria-label="user menu"
                    position="bottom-end"
                    // transition="pop-top-right"
                    trigger="click"
                    radius="xs"
                    // onClose={() => setUserMenuOpened(false)}
                    // onOpen={() => setUserMenuOpened(true)}
                >
                    <Menu.Target>
                        {/*FOR FUTURE*/}
                        {/*<Indicator
                            // label={profileStatus}
                            dot={true}
                            inline={true}
                            size={15}
                            offset={4}
                            // position="bottom-end"
                            color="red"
                            withBorder={true}
                        >*/}
                        <UserAvatar
                            icon={
                                <IconMenu2
                                    size={18}
                                    color={colors.gray[6]}
                                />
                            }
                            image={image !== null ? `${S3_USER_BUCKET_URL}/${image}?ttl=${generateKey()}` : null}
                            name={displayAvatarName(name)}
                            email={email}
                        />
                        {/*</Indicator>*/}
                    </Menu.Target>

                    <Menu.Dropdown>

                        <Menu.Item icon={<IconUser size={20}/>}>
                            <div style={{ flex: 1, marginLeft: 6 }}>
                                <Text size="sm" weight={400}>
                                    {name}
                                </Text>

                                <Text color="dimmed" size="xs">
                                    {email}
                                </Text>
                            </div>
                        </Menu.Item>

                        <Menu.Divider/>

                        <Menu.Item
                            p={10}
                            component={NextLink}
                            href={AppRoutes.FAVORITES}
                            title="Favorites"
                            icon={
                                <IconHeart size={20} color={colors.red[6]}/>
                            }
                        >
                            <Text ml={6} size="sm" weight={400}>Favorites</Text>
                        </Menu.Item>

                        <Menu.Item
                            p={10}
                            component={NextLink}
                            href={AppRoutes.BOOKINGS}
                            title="Bookings"
                            icon={
                                <IconStar size={20} color={colors.yellow[6]}/>
                            }
                        >
                            <Text ml={6} size="sm" weight={400}>Bookings</Text>
                        </Menu.Item>

                        <Menu.Divider/>

                        {/*<Menu.Label>Settings</Menu.Label>*/}

                        <Menu.Item
                            p={10}
                            component={NextLink}
                            href={AppRoutes.ACCOUNT}
                            title="Account Settings"
                            icon={
                                <IconSettings size={20} color={colors.blue[6]}/>
                            }
                        >
                            <Text ml={6} size="sm" weight={400}>Account Settings</Text>
                        </Menu.Item>

                        <Menu.Item
                            p={10}
                            component={NextLink}
                            href={AppRoutes.CHANGE_PASSWORD}
                            title="Change Password"
                            icon={
                                <IconLock size={20} color={colors.green[6]}/>
                            }
                        >
                            <Text ml={6} size="sm" weight={400}>Change Password</Text>
                        </Menu.Item>

                        <Menu.Divider/>

                        <Menu.Item
                            p={10}
                            icon={
                                <IconLogout size={20} color={colors.gray[6]}/>
                            }
                            closeMenuOnClick={true}
                        >
                            <LogoutButton/>
                        </Menu.Item>
                    </Menu.Dropdown>
                </Menu>
            </Group>
        </Container>
    );
};
