import { ReactNode } from "react";
import createSafeContext from "@hooks/useSafeContext";
import { useLocalStorage } from "@hooks/useLocalStorage";
import { Image, PropertyDetails } from "../../generated/graphql";

export interface PropertyStateProps {
    propertyDetailsData?: PropertyDetails;
    setPropertyDetailsData?: (data: string) => void;
    resetPropertyDetailsData?: () => void;
    getAllGalleryImages: () => Image[];
    getGalleryFrontImages: () => Image[];
    findImageIndex: (alt: string) => void;
    selectedImageIndex?: number;
    // setImageSrc: (src: string | StaticImageData) => void;
}

export const PROPERTY_STATE_KEYS = {
    propertyDetailsData: 'PROPERTY_DETAILS_DATA',
    selectedImageIndex: 'SELECTED_IMAGE_INDEX',
    imageSrc: 'IMAGE_SRC'
}

export const [usePropertyState, Provider] = createSafeContext<PropertyStateProps>();

export const PropertyStateProvider = ({ children }: { children: ReactNode }) => {

    /*const [imageSrc, setImageSrc] =
        useLocalStorage(STATE_KEYS.imageSrc, null, { sync: true });*/

    const [selectedImageIndex, setSelectedImageIndex] =
        useLocalStorage(PROPERTY_STATE_KEYS.selectedImageIndex, '0', { sync: true });

    const [propertyDetailsData, setPropertyDetailsData] =
        useLocalStorage(PROPERTY_STATE_KEYS.propertyDetailsData, "", { sync: true });

    const resetPropertyDetailsData = () => setPropertyDetailsData(null);


    const getGalleryImages = () => {

    }

    const getAllGalleryImages = () => {
        return propertyDetailsData ? JSON.parse(propertyDetailsData).images?.filter((img) => img.type === 'image' && img) : []
    }

    const getGalleryFrontImages = () => {

        const frontPositionImages = getAllGalleryImages().filter((img) => img.position !== -1);
        const frontImages = [];

        // RE INDEXING ACCORDING TO POSITION
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < frontPositionImages.length; i++) {
            frontImages[frontPositionImages[i].position] = frontPositionImages[i]
        }

        return frontImages;
    }

    function findImageIndex(_alt: string) {
        const index = getAllGalleryImages().findIndex(img => img.alt === _alt);

        if(selectedImageIndex){
            setSelectedImageIndex(JSON.stringify(index))
        }else{
            setSelectedImageIndex(JSON.stringify(0))
        }
    }

    const providerValues: PropertyStateProps = {
        propertyDetailsData: propertyDetailsData ? JSON.parse(propertyDetailsData) : null,
        setPropertyDetailsData,
        resetPropertyDetailsData,
        getAllGalleryImages,
        getGalleryFrontImages,
        findImageIndex,
        selectedImageIndex: JSON.parse(selectedImageIndex)
    };

    return <Provider value={providerValues}>{children}</Provider>;
}
