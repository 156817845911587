import React                from 'react';
import { Text }             from '@mantine/core';
import { signOut }          from 'next-auth/react';
import useUserData          from '@features/common/hooks/use-user-data';
import useAppData           from '@hooks/use-app-data';
import { AppRoutes }        from '@core/router/AppRoutes';
import {useBookingState} from "@core/providers/BookingStateProvider";


export const LogoutButton: React.FC = () => {

    const { clearUserProfileData, clearUserPropIds } = useUserData();
    const { resetPrices, resetBookingInfo } = useBookingState(); // LOCAL CALCULATIONS
    const { setLastPage } = useAppData();

    const onLogout = async () => {

        clearUserProfileData();
        clearUserPropIds();

        resetBookingInfo();
        resetPrices();

        setLastPage(AppRoutes.HOME);

        await signOut({
            callbackUrl: process.env.NEXTAUTH_URL || 'http://localhost:8080',
        });
    };

    return <Text title="Logout" onClick={onLogout} ml={6} size="sm" weight={400}>Logout</Text>;
};
