import { createStyles } from '@mantine/styles';


export default createStyles((
    theme: {
        colors: { [x: string]: any[] };
        fn: any;
        colorScheme: string;
        radius: { sm: any };
        fontSizes: { sm: any };
    }) => ({

    group: {
        [theme.fn.smallerThan('sm')]: {
            display: 'none',
        },
    },

    links: {
        [theme.fn.smallerThan('xs')]: {
            display: 'none',
        },
    },

    link: {
        display: 'block',
        lineHeight: 1,
        padding: '11px 20px',
        borderRadius: theme.radius.sm,
        // borderStyle: "solid",
        // borderWidth: 1,
        // borderColor: theme.colors.gray[3],
        textDecoration: 'none',
        color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
        fontSize: theme.fontSizes.sm,
        fontWeight: 400,

        '&:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
            color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
            transition: 'background-color 0.3s ease-in-out',
        },
    },

    /*
        linkActive: {
            '&, &:hover': {
                backgroundColor: theme.fn.variant({variant: 'light', color: theme.primaryColor}).background,
                color: theme.fn.variant({variant: 'light', color: theme.primaryColor}).color,
            },
        },*/
}));
