import { createStyles } from '@mantine/styles';


export default createStyles((
    theme: {
        colors: { [x: string]: any[] };
        colorScheme: string;
        fontSizes: any;
        spacing: any;
        fn: any;
        other: any;
    }) => ({

   /* footer: {
        color: theme.colors.gray[6],
        // marginTop: 30,
        paddingLeft: 42,
        paddingRight: 42,
        // borderTop: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]}`,
    },*/

    inner: {
        minHeight: 100,
        maxWidth: theme.other.maxWidth,
        fontSize: theme.fontSizes.sm,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: theme.spacing.xl,
        paddingBottom: theme.spacing.xl,

        [theme.fn.smallerThan('sm')]: {
            flexDirection: 'column',
        },
    },

    links: {
        [theme.fn.smallerThan('sm')]: {
            marginTop: theme.spacing.md,
            width: '100%',
            justifyContent: 'center',
        },

        'a': {
            color: theme.colorScheme === 'dark' ? theme.colors.gray[0] : theme.colors.gray[7],
        },

        'a:hover': {
            color: theme.colors.orange[5],
            textDecoration: 'none',
        },

        width: '33.33%',
        justifyContent: 'flex-start',
    },

    copyright: {
        [theme.fn.smallerThan('sm')]: {
            marginTop: theme.spacing.md,
            width: '100%',
        },
        color: theme.colorScheme === 'dark' ? theme.colors.gray[0] : theme.colors.gray[7],
        width: '33.33%',
        justifyContent: 'center',
        display: 'flex',
    },

    social: {
        [theme.fn.smallerThan('sm')]: {
            marginTop: theme.spacing.md,
            width: '100%',
            justifyContent: 'center',
        },
        width: '33.33%',
        justifyContent: 'flex-end',
    },

    stripe: {
        display: 'inline-flex',
    },

    instagram: {
        color: theme.colorScheme === 'dark' ? theme.colors.gray[0] : theme.colors.gray[7],
        height: 28,
        width: 34,
        verticalAlign: 'middle',
        paddingLeft: 6,
        marginLeft: 10,
        borderLeft: 1,
        borderRight: 0,
        borderTop: 0,
        borderBottom: 0,
        borderStyle: 'solid',
        borderColor: theme.colors.gray[6],
    }

}));
