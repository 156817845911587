import React from 'react';
import { ActionIcon, Group, useMantineColorScheme } from "@mantine/core";
import { IconMoon, IconSun } from "@tabler/icons";


export const ColorSchemeButton: React.FC = () => {

    const { colorScheme, toggleColorScheme } = useMantineColorScheme();

    const Icon = colorScheme === 'dark' ? IconSun : IconMoon;

    return (
        <Group position="center" my="xl">

            <ActionIcon
                title="Change Theme"
                aria-label="Dark / Light Theme"
                variant="outline"
                onClick={() => toggleColorScheme()}
                size="lg"
                sx={(theme) => ({
                    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
                    color: theme.colorScheme === 'dark' ? theme.colors.orange[4] : theme.colors.gray[6],
                    borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[4],
                })}
            >
                <Icon size="1.5rem" stroke={1.2}/>
            </ActionIcon>

        </Group>
    );
};
