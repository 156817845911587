// THIS IS A GENERATED FILE, use `yarn codegen` to regenerate
/* tslint:disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    Date: any;
    JSON: any;
    JSONObject: any;
    Upload: any;
};

export type AmenitySelector = {
    __typename?: 'AmenitySelector';
    id: Scalars['ID'];
    name: Scalars['String'];
};

export type ApartmentDetails = {
    __typename?: 'ApartmentDetails';
    amenities?: Maybe<Array<Maybe<Scalars['String']>>>;
    currency?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['Int']>;
    location?: Maybe<ApartmentLocation>;
    name?: Maybe<Scalars['String']>;
    price?: Maybe<ApartmentPrice>;
    rooms?: Maybe<ApartmentRoom>;
    timeZone?: Maybe<Scalars['String']>;
    type?: Maybe<ApartmentType>;
};

export type ApartmentId = {
    __typename?: 'ApartmentId';
    id?: Maybe<Scalars['Int']>;
    name?: Maybe<Scalars['String']>;
};

export type ApartmentIdsList = {
    __typename?: 'ApartmentIdsList';
    apartmentIds?: Maybe<Array<Maybe<ApartmentId>>>;
};

export type ApartmentLocation = {
    __typename?: 'ApartmentLocation';
    city?: Maybe<Scalars['String']>;
    country?: Maybe<Scalars['String']>;
    latitude?: Maybe<Scalars['String']>;
    longitude?: Maybe<Scalars['String']>;
    street?: Maybe<Scalars['String']>;
    zip?: Maybe<Scalars['String']>;
};

export type ApartmentPrice = {
    __typename?: 'ApartmentPrice';
    maximal?: Maybe<Scalars['String']>;
    minimal?: Maybe<Scalars['String']>;
};

export type ApartmentRoom = {
    __typename?: 'ApartmentRoom';
    bathrooms?: Maybe<Scalars['Int']>;
    bedrooms?: Maybe<Scalars['Int']>;
    childBeds?: Maybe<Scalars['String']>;
    couches?: Maybe<Scalars['String']>;
    doubleBeds?: Maybe<Scalars['Int']>;
    kingSizeBeds?: Maybe<Scalars['Int']>;
    maxOccupancy?: Maybe<Scalars['Int']>;
    queenSizeBeds?: Maybe<Scalars['Int']>;
    singleBeds?: Maybe<Scalars['Int']>;
    sofaBeds?: Maybe<Scalars['String']>;
};

export type ApartmentType = {
    __typename?: 'ApartmentType';
    id?: Maybe<Scalars['Int']>;
    name?: Maybe<Scalars['String']>;
};

export type AssetTypeSelector = {
    __typename?: 'AssetTypeSelector';
    id: Scalars['ID'];
    name: Scalars['String'];
};

export type AuthInput = {
    email: Scalars['String'];
    password: Scalars['String'];
};

export enum AuthMethod {
    Both = 'BOTH',
    EmailOnly = 'EMAIL_ONLY',
    NotSecure = 'NOT_SECURE',
    SmsOnly = 'SMS_ONLY',
}

export type Booking = {
    __typename?: 'Booking';
    dxb_stay_price_ids?: Maybe<Scalars['String']>;
    end_date?: Maybe<Scalars['String']>;
    grand_total_price?: Maybe<Scalars['Float']>;
    guests?: Maybe<Scalars['Int']>;
    id?: Maybe<Scalars['ID']>;
    price_per_night?: Maybe<Scalars['Float']>;
    property?: Maybe<Property>;
    reservation_id?: Maybe<Scalars['ID']>;
    start_date?: Maybe<Scalars['String']>;
    status?: Maybe<Scalars['String']>;
    stripe_price_id?: Maybe<Scalars['String']>;
    total_nights?: Maybe<Scalars['Int']>;
    total_price?: Maybe<Scalars['Float']>;
    user?: Maybe<UserProfile>;
};

export type BookingLog = {
    __typename?: 'BookingLog';
    end_date?: Maybe<Scalars['String']>;
    grand_total_price?: Maybe<Scalars['String']>;
    guests?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    price_per_night?: Maybe<Scalars['String']>;
    property?: Maybe<Property>;
    security_deposit_fees?: Maybe<Scalars['String']>;
    smoobu_reservation_id?: Maybe<Scalars['ID']>;
    start_date?: Maybe<Scalars['String']>;
    status?: Maybe<Scalars['String']>;
    total_nights?: Maybe<Scalars['String']>;
    total_price?: Maybe<Scalars['Float']>;
    tourism_admin_fees?: Maybe<Scalars['String']>;
    tourism_fees?: Maybe<Scalars['String']>;
    user?: Maybe<UserProfile>;
};

export enum CacheControlScope {
    Private = 'PRIVATE',
    Public = 'PUBLIC',
}

export type CancelBookingInput = {
    property_id?: InputMaybe<Scalars['ID']>;
    reservationId: Scalars['ID'];
    user_id?: InputMaybe<Scalars['ID']>;
};

export type CarousalSlideInput = {
    background_image_url: Scalars['String'];
    description?: InputMaybe<Scalars['String']>;
    heading: Scalars['String'];
    status: Scalars['String'];
    target_url?: InputMaybe<Scalars['String']>;
    title: Scalars['String'];
};

export type CarousalSlides = {
    __typename?: 'CarousalSlides';
    background_image_url?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    heading?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    status: Scalars['String'];
    target_url?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
};

export type ChangePasswordInput = {
    newPass: Scalars['String'];
    oldPass?: InputMaybe<Scalars['String']>;
    userId: Scalars['ID'];
};

export type ChannelSelector = {
    __typename?: 'ChannelSelector';
    id: Scalars['ID'];
    name: Scalars['String'];
    smoobu_id: Scalars['ID'];
};

export type Content = {
    __typename?: 'Content';
    description?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
};

export type ContentInput = {
    description?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<Scalars['String']>;
};

export type CreateBookingInput = {
    end_date?: InputMaybe<Scalars['String']>;
    guests?: InputMaybe<Scalars['Int']>;
    property_id?: InputMaybe<Scalars['ID']>;
    start_date?: InputMaybe<Scalars['String']>;
    user_id?: InputMaybe<Scalars['ID']>;
};

export type CreateBookingLogInput = {
    end_date?: InputMaybe<Scalars['String']>;
    grand_total_price?: InputMaybe<Scalars['String']>;
    guests?: InputMaybe<Scalars['String']>;
    price_per_night?: InputMaybe<Scalars['String']>;
    property_id?: InputMaybe<Scalars['ID']>;
    property_smoobu_id?: InputMaybe<Scalars['ID']>;
    security_deposit_fees?: InputMaybe<Scalars['String']>;
    smoobu_reservation_id?: InputMaybe<Scalars['ID']>;
    start_date?: InputMaybe<Scalars['String']>;
    status?: InputMaybe<Scalars['String']>;
    total_nights?: InputMaybe<Scalars['String']>;
    total_price?: InputMaybe<Scalars['Float']>;
    tourism_admin_fees?: InputMaybe<Scalars['String']>;
    tourism_fees?: InputMaybe<Scalars['String']>;
    user_id?: InputMaybe<Scalars['ID']>;
};

export type CreateBookingPaymentInput = {
    end_date?: InputMaybe<Scalars['String']>;
    guests?: InputMaybe<Scalars['Int']>;
    property_id?: InputMaybe<Scalars['ID']>;
    start_date?: InputMaybe<Scalars['String']>;
    user_id?: InputMaybe<Scalars['ID']>;
};

export type CreatePassportInput = {
    back_image?: InputMaybe<Scalars['String']>;
    front_image?: InputMaybe<Scalars['String']>;
    image?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<Scalars['String']>;
    number?: InputMaybe<Scalars['String']>;
    user_id: Scalars['ID'];
};

export type CreatePropertyInput = {
    details?: InputMaybe<DetailsInput>;
    images?: InputMaybe<Array<InputMaybe<ImageInput>>>;
    location?: InputMaybe<LocationInput>;
    parent_id?: InputMaybe<Scalars['ID']>;
    prices?: InputMaybe<PriceInput>;
    settings?: InputMaybe<SettingsInput>;
    smoobu_id?: InputMaybe<Scalars['ID']>;
    stripe_product_id?: InputMaybe<Scalars['ID']>;
};

export type CreateUserInput = {
    country: Scalars['String'];
    email: Scalars['String'];
    first_name: Scalars['String'];
    last_name: Scalars['String'];
    name?: InputMaybe<Scalars['String']>;
    password: Scalars['String'];
    phone: Scalars['String'];
    profile_status?: InputMaybe<RecordStatus>;
    status: RecordStatus;
};

export type Details = {
    __typename?: 'Details';
    amenities?: Maybe<Array<Maybe<Scalars['String']>>>;
    bath_rooms?: Maybe<Scalars['Int']>;
    bed_rooms?: Maybe<Scalars['Int']>;
    child_beds?: Maybe<Scalars['Int']>;
    couches?: Maybe<Scalars['Int']>;
    double_beds?: Maybe<Scalars['Int']>;
    king_size_beds?: Maybe<Scalars['Int']>;
    max_occupancy?: Maybe<Scalars['Int']>;
    queen_size_beds?: Maybe<Scalars['Int']>;
    single_beds?: Maybe<Scalars['Int']>;
    size?: Maybe<Scalars['Int']>;
    sofa_beds?: Maybe<Scalars['Int']>;
    type?: Maybe<Scalars['String']>;
};

export type DetailsInput = {
    amenities?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    bath_rooms?: InputMaybe<Scalars['Int']>;
    bed_rooms?: InputMaybe<Scalars['Int']>;
    child_beds?: InputMaybe<Scalars['Int']>;
    couches?: InputMaybe<Scalars['Int']>;
    double_beds?: InputMaybe<Scalars['Int']>;
    king_size_beds?: InputMaybe<Scalars['Int']>;
    max_occupancy?: InputMaybe<Scalars['Int']>;
    queen_size_beds?: InputMaybe<Scalars['Int']>;
    single_beds?: InputMaybe<Scalars['Int']>;
    size?: InputMaybe<Scalars['Int']>;
    sofa_beds?: InputMaybe<Scalars['Int']>;
    type?: InputMaybe<Scalars['String']>;
};

export type ErrorResponse = {
    __typename?: 'ErrorResponse';
    code?: Maybe<Scalars['String']>;
    exception?: Maybe<Scalars['String']>;
    message: Scalars['String'];
};

export type FeaturedPropertyList = {
    __typename?: 'FeaturedPropertyList';
    description?: Maybe<Scalars['String']>;
    details?: Maybe<Details>;
    id?: Maybe<Scalars['ID']>;
    images?: Maybe<Array<Maybe<Image>>>;
    location?: Maybe<Location>;
    name: Scalars['String'];
    parent_id?: Maybe<Scalars['ID']>;
    priceDetails?: Maybe<PriceDetails>;
    settings?: Maybe<Settings>;
    smoobu_id?: Maybe<Scalars['ID']>;
    stripe_product_id?: Maybe<Scalars['ID']>;
};

export type FileInput = {
    fileURI?: InputMaybe<Scalars['String']>;
    filename?: InputMaybe<Scalars['String']>;
};

export type FilterOptionsInput = {
    columns: Scalars['String'];
    searchBy: Scalars['String'];
};

export type ForgetPasswordInput = {
    email: Scalars['String'];
};

export enum Gender {
    Female = 'FEMALE',
    Male = 'MALE',
    Unknown = 'UNKNOWN',
}

export type GetPropertyCalendarInput = {
    property_id: Scalars['ID'];
    smoobu_id: Scalars['ID'];
};

export type GqlResponse = {
    __typename?: 'GqlResponse';
    data?: Maybe<Scalars['String']>;
    error?: Maybe<Scalars['String']>;
    status: Status;
};

export type GuestCount = {
    __typename?: 'GuestCount';
    max?: Maybe<Scalars['Int']>;
};

export type Image = {
    __typename?: 'Image';
    alt?: Maybe<Scalars['String']>;
    height?: Maybe<Scalars['Int']>;
    id?: Maybe<Scalars['ID']>;
    position?: Maybe<Scalars['Int']>;
    property_id?: Maybe<Scalars['ID']>;
    src?: Maybe<Scalars['String']>;
    status?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
    type?: Maybe<Scalars['String']>;
    width?: Maybe<Scalars['Int']>;
};

export type ImageInput = {
    alt?: InputMaybe<Scalars['String']>;
    height?: InputMaybe<Scalars['Int']>;
    id?: InputMaybe<Scalars['ID']>;
    position?: InputMaybe<Scalars['Int']>;
    src?: InputMaybe<Scalars['String']>;
    status?: InputMaybe<Scalars['String']>;
    title?: InputMaybe<Scalars['String']>;
    type?: InputMaybe<Scalars['String']>;
    width?: InputMaybe<Scalars['Int']>;
};

export type Inquiry = {
    __typename?: 'Inquiry';
    country: Scalars['String'];
    email: Scalars['String'];
    id: Scalars['ID'];
    inquiryType?: Maybe<Scalars['String']>;
    isRead?: Maybe<Scalars['String']>;
    message: Scalars['String'];
    name: Scalars['String'];
    phone: Scalars['String'];
    userType?: Maybe<Scalars['String']>;
};

export type InquiryInput = {
    country: Scalars['String'];
    email: Scalars['String'];
    inquiryType?: InputMaybe<Scalars['String']>;
    message: Scalars['String'];
    name: Scalars['String'];
    phone: Scalars['String'];
    userType?: InputMaybe<Scalars['String']>;
};

export type Location = {
    __typename?: 'Location';
    address?: Maybe<Scalars['String']>;
    city?: Maybe<Scalars['String']>;
    country?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    keywords?: Maybe<Scalars['String']>;
    latitude?: Maybe<Scalars['Float']>;
    longitude?: Maybe<Scalars['Float']>;
    state?: Maybe<Scalars['String']>;
    street?: Maybe<Scalars['String']>;
    zip?: Maybe<Scalars['String']>;
};

export type LocationInput = {
    address?: InputMaybe<Scalars['String']>;
    city?: InputMaybe<Scalars['String']>;
    country?: InputMaybe<Scalars['String']>;
    description?: InputMaybe<Scalars['String']>;
    keywords?: InputMaybe<Scalars['String']>;
    latitude?: InputMaybe<Scalars['Float']>;
    longitude?: InputMaybe<Scalars['Float']>;
    state?: InputMaybe<Scalars['String']>;
    street?: InputMaybe<Scalars['String']>;
    zip?: InputMaybe<Scalars['String']>;
};

export type Mutation = {
    __typename?: 'Mutation';
    addCarousalSlide?: Maybe<GqlResponse>;
    addPropertyToStripe: GqlResponse;
    authenticate: GqlResponse;
    cancelSmoobuDxbBooking: GqlResponse;
    changePassword: GqlResponse;
    createBooking: GqlResponse;
    createBookingLog: GqlResponse;
    createBookingPaymentSession: GqlResponse;
    createNewPassport: GqlResponse;
    createProperty: GqlResponse;
    createStripeProduct: GqlResponse;
    forgetPassword: GqlResponse;
    registerUser: GqlResponse;
    removeCarousalSlide?: Maybe<GqlResponse>;
    removePropertyFromStripe: GqlResponse;
    removePropertyImage: GqlResponse;
    removePropertyPrice: GqlResponse;
    removeUserFavProperty: GqlResponse;
    resendConfirmEmail: GqlResponse;
    resetPassword: GqlResponse;
    revalidateData?: Maybe<GqlResponse>;
    savePropertyCalendar: GqlResponse;
    savePropertyImage: GqlResponse;
    savePropertyImages: GqlResponse;
    savePropertyMetaConfigs: GqlResponse;
    savePropertyPrice: GqlResponse;
    saveUserFavProperty: GqlResponse;
    saveUserProperty: GqlResponse;
    sendInquiryEmail: GqlResponse;
    syncSmoobuProperties: GqlResponse;
    updateBooking: GqlResponse;
    updateCarousalSlide?: Maybe<GqlResponse>;
    updatePassportStatus: GqlResponse;
    updatePriceType: GqlResponse;
    updatePropertyDetails: GqlResponse;
    updatePropertyImage: GqlResponse;
    updatePropertyLocation: GqlResponse;
    updatePropertyMetaConfigs: GqlResponse;
    updatePropertyPrice: GqlResponse;
    updatePropertySettings: GqlResponse;
    updateUserAvatar: GqlResponse;
    updateUserProfile: GqlResponse;
    whoami?: Maybe<Scalars['String']>;
};

export type MutationAddCarousalSlideArgs = {
    input: CarousalSlideInput;
};

export type MutationAddPropertyToStripeArgs = {
    propertyId: Scalars['ID'];
};

export type MutationAuthenticateArgs = {
    input: AuthInput;
};

export type MutationCancelSmoobuDxbBookingArgs = {
    input: CancelBookingInput;
};

export type MutationChangePasswordArgs = {
    input: ChangePasswordInput;
};

export type MutationCreateBookingArgs = {
    input: CreateBookingInput;
};

export type MutationCreateBookingLogArgs = {
    input: CreateBookingLogInput;
};

export type MutationCreateBookingPaymentSessionArgs = {
    input: CreateBookingPaymentInput;
};

export type MutationCreateNewPassportArgs = {
    input: CreatePassportInput;
};

export type MutationCreatePropertyArgs = {
    input: CreatePropertyInput;
};

export type MutationCreateStripeProductArgs = {
    input: StripeProductInput;
};

export type MutationForgetPasswordArgs = {
    input: ForgetPasswordInput;
};

export type MutationRegisterUserArgs = {
    input: CreateUserInput;
};

export type MutationRemoveCarousalSlideArgs = {
    id: Scalars['ID'];
};

export type MutationRemovePropertyFromStripeArgs = {
    propertyId: Scalars['ID'];
};

export type MutationRemovePropertyImageArgs = {
    imageId: Scalars['ID'];
};

export type MutationRemovePropertyPriceArgs = {
    priceId: Scalars['ID'];
};

export type MutationRemoveUserFavPropertyArgs = {
    propertyId: Scalars['ID'];
    userId: Scalars['ID'];
};

export type MutationResendConfirmEmailArgs = {
    input: ResendConfirmEmailInput;
};

export type MutationResetPasswordArgs = {
    input: ResetPasswordInput;
};

export type MutationRevalidateDataArgs = {
    pathToRevalidate: Scalars['String'];
    token: Scalars['String'];
};

export type MutationSavePropertyCalendarArgs = {
    input: PropertyCalendarInput;
};

export type MutationSavePropertyImageArgs = {
    input: ImageInput;
    propertyId: Scalars['ID'];
};

export type MutationSavePropertyImagesArgs = {
    input: PropertyImageInput;
};

export type MutationSavePropertyMetaConfigsArgs = {
    input: PropertyMetaConfigInput;
};

export type MutationSavePropertyPriceArgs = {
    input: PropertyPricesInput;
};

export type MutationSaveUserFavPropertyArgs = {
    propertyId: Scalars['ID'];
    userId: Scalars['ID'];
};

export type MutationSaveUserPropertyArgs = {
    input: SaveUserPropertyInput;
};

export type MutationSendInquiryEmailArgs = {
    input: InquiryInput;
};

export type MutationUpdateBookingArgs = {
    input: UpdateBookingInput;
};

export type MutationUpdateCarousalSlideArgs = {
    id: Scalars['ID'];
    input: CarousalSlideInput;
};

export type MutationUpdatePassportStatusArgs = {
    input: UpdatePassportStatusInput;
};

export type MutationUpdatePriceTypeArgs = {
    input: UpdatePriceTypeInput;
};

export type MutationUpdatePropertyDetailsArgs = {
    input: UpdatePropertyInput;
};

export type MutationUpdatePropertyImageArgs = {
    input: UpdatePropertyInput;
};

export type MutationUpdatePropertyLocationArgs = {
    input: UpdatePropertyInput;
};

export type MutationUpdatePropertyMetaConfigsArgs = {
    input: PropertyMetaConfigInput;
};

export type MutationUpdatePropertyPriceArgs = {
    input: PropertyPricesInput;
};

export type MutationUpdatePropertySettingsArgs = {
    input: UpdatePropertyInput;
};

export type MutationUpdateUserAvatarArgs = {
    image: Scalars['String'];
    userId: Scalars['ID'];
};

export type MutationUpdateUserProfileArgs = {
    input: UpdateUserProfileInput;
};

export type Passport = {
    __typename?: 'Passport';
    back_image?: Maybe<Scalars['String']>;
    front_image?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    image?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    number?: Maybe<Scalars['String']>;
    status?: Maybe<Scalars['String']>;
    user_id: Scalars['ID'];
};

export type Price = {
    __typename?: 'Price';
    amount?: Maybe<Scalars['Int']>;
    currency_code?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    is_percentage?: Maybe<Scalars['Boolean']>;
    maximal?: Maybe<Scalars['String']>;
    minimal?: Maybe<Scalars['String']>;
    priceType?: Maybe<PriceType>;
    price_type_id?: Maybe<Scalars['Int']>;
    status?: Maybe<Scalars['String']>;
    tax?: Maybe<Scalars['String']>;
};

export type PriceDetails = {
    __typename?: 'PriceDetails';
    pricePerNight?: Maybe<Scalars['Int']>;
};

export type PriceInput = {
    amount: Scalars['Float'];
    currency_code?: InputMaybe<Scalars['String']>;
    is_percentage?: InputMaybe<Scalars['Boolean']>;
    maximal?: InputMaybe<Scalars['Float']>;
    minimal?: InputMaybe<Scalars['Float']>;
    price_type_id?: InputMaybe<Scalars['ID']>;
    status?: InputMaybe<Scalars['String']>;
    tax?: InputMaybe<Scalars['Float']>;
};

export type PriceType = {
    __typename?: 'PriceType';
    condition?: Maybe<Scalars['String']>;
    conditional?: Maybe<Scalars['Boolean']>;
    id?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
    status?: Maybe<Scalars['String']>;
};

export type PriceTypeInput = {
    condition?: InputMaybe<Scalars['String']>;
    conditional?: InputMaybe<Scalars['Boolean']>;
    name?: InputMaybe<Scalars['String']>;
    status?: InputMaybe<Scalars['String']>;
};

export type PriceTypeSelector = {
    __typename?: 'PriceTypeSelector';
    id: Scalars['ID'];
    name: Scalars['String'];
};

export type Property = {
    __typename?: 'Property';
    description?: Maybe<Scalars['String']>;
    details?: Maybe<Details>;
    id?: Maybe<Scalars['ID']>;
    images?: Maybe<Array<Maybe<Image>>>;
    location?: Maybe<Location>;
    name: Scalars['String'];
    parent_id?: Maybe<Scalars['ID']>;
    prices?: Maybe<Array<Maybe<Price>>>;
    settings?: Maybe<Settings>;
    smoobu_id?: Maybe<Scalars['ID']>;
    stripe_product_id?: Maybe<Scalars['ID']>;
};

export type PropertyCalendar = {
    __typename?: 'PropertyCalendar';
    available?: Maybe<Scalars['Boolean']>;
    blocked?: Maybe<Scalars['Boolean']>;
    date?: Maybe<Scalars['String']>;
    min_length_of_stay?: Maybe<Scalars['Int']>;
    price?: Maybe<Scalars['Float']>;
};

export type PropertyCalendarInput = {
    dates?: InputMaybe<Array<InputMaybe<PropertyDateInput>>>;
    property_id: Scalars['ID'];
};

export type PropertyDateInput = {
    available?: InputMaybe<Scalars['Boolean']>;
    blocked?: InputMaybe<Scalars['Boolean']>;
    date: Scalars['String'];
    price?: InputMaybe<Scalars['Float']>;
};

export type PropertyDetails = {
    __typename?: 'PropertyDetails';
    description?: Maybe<Scalars['String']>;
    details?: Maybe<Details>;
    id?: Maybe<Scalars['ID']>;
    images?: Maybe<Array<Maybe<Image>>>;
    location?: Maybe<Location>;
    name: Scalars['String'];
    parent_id?: Maybe<Scalars['ID']>;
    priceDetails?: Maybe<PriceDetails>;
    settings?: Maybe<Settings>;
    smoobu_id?: Maybe<Scalars['ID']>;
    stripe_product_id?: Maybe<Scalars['ID']>;
};

export type PropertyDetailsRemoteLocal = {
    __typename?: 'PropertyDetailsRemoteLocal';
    local?: Maybe<PropertyDetails>;
    remote?: Maybe<ApartmentDetails>;
};

export type PropertyIds = {
    __typename?: 'PropertyIDS';
    property_id: Scalars['ID'];
};

export type PropertyImageInput = {
    images?: InputMaybe<Array<InputMaybe<ImageInput>>>;
    property_id: Scalars['ID'];
};

export type PropertyList = {
    __typename?: 'PropertyList';
    description?: Maybe<Scalars['String']>;
    details?: Maybe<Details>;
    id?: Maybe<Scalars['ID']>;
    images?: Maybe<Array<Maybe<Image>>>;
    location?: Maybe<Location>;
    name: Scalars['String'];
    parent_id?: Maybe<Scalars['ID']>;
    priceDetails?: Maybe<PriceDetails>;
    settings?: Maybe<Settings>;
    smoobu_id?: Maybe<Scalars['ID']>;
    stripe_product_id?: Maybe<Scalars['ID']>;
};

export type PropertyLocationInput = {
    location?: InputMaybe<LocationInput>;
    property_id: Scalars['ID'];
};

export type PropertyMetaConfig = {
    __typename?: 'PropertyMetaConfig';
    calendarState?: Maybe<RecordStatus>;
    minimumNights?: Maybe<Scalars['Int']>;
    propertyID: Scalars['ID'];
    smoobuID: Scalars['ID'];
    type?: Maybe<Scalars['String']>;
};

export type PropertyMetaConfigInput = {
    calendarState?: InputMaybe<RecordStatus>;
    minimumNights?: InputMaybe<Scalars['Int']>;
    propertyID: Scalars['ID'];
    smoobuID: Scalars['ID'];
    type?: InputMaybe<Scalars['String']>;
};

export type PropertyPrices = {
    __typename?: 'PropertyPrices';
    dxbstay?: Maybe<Array<Maybe<Price>>>;
    smoobu?: Maybe<Array<Maybe<PropertyCalendar>>>;
};

export type PropertyPricesInput = {
    prices?: InputMaybe<PriceInput>;
    property_id: Scalars['ID'];
};

export type PropertySearchInput = {
    endDate?: InputMaybe<Scalars['String']>;
    guestCount?: InputMaybe<Scalars['Int']>;
    keyword?: InputMaybe<Scalars['String']>;
    searchTag?: InputMaybe<Scalars['String']>;
    startDate?: InputMaybe<Scalars['String']>;
};

export type PropertySettingsInput = {
    property_id: Scalars['ID'];
    settings?: InputMaybe<SettingsInput>;
};

export type PropertySlug = {
    __typename?: 'PropertySlug';
    slug: Scalars['String'];
};

export type Query = {
    __typename?: 'Query';
    getAdminProperties: Array<Maybe<PropertyList>>;
    getAdminPropertyBySlug: PropertyDetails;
    getAllApartments: Array<Maybe<ApartmentDetails>>;
    getAllCarousalSlides?: Maybe<Array<Maybe<CarousalSlides>>>;
    getAllPassports: Array<Maybe<Passport>>;
    getAllSettings: Array<Maybe<Settings>>;
    getAllUsers: Array<Maybe<UserProfile>>;
    getAmenitySelectorData: Array<Maybe<AmenitySelector>>;
    getApartmentDetails: ApartmentDetails;
    getApartmentIds: ApartmentIdsList;
    getAssetTypeSelectorData: Array<Maybe<AssetTypeSelector>>;
    getBookingLogsByReservationId: Array<Maybe<BookingLog>>;
    getBookings: Array<Maybe<Booking>>;
    getChannelSelectorData: Array<Maybe<ChannelSelector>>;
    getFeaturedProperties: Array<Maybe<FeaturedPropertyList>>;
    getMaxGuestCount: GuestCount;
    getPriceTypeSelectorData: Array<Maybe<PriceTypeSelector>>;
    getPriceTypes: Array<Maybe<PriceType>>;
    getPrices: PropertyPrices;
    getProperties: Array<Maybe<PropertyList>>;
    getPropertyBookings: Array<Maybe<Booking>>;
    getPropertyById: PropertyDetails;
    getPropertyBySlug: PropertyDetails;
    getPropertyCalendar: Array<Maybe<PropertyCalendar>>;
    getPropertyContent: Content;
    getPropertyDetails: Details;
    getPropertyImages: Array<Maybe<Image>>;
    getPropertyLocation: Location;
    getPropertyMetaConfigs: PropertyMetaConfig;
    getPropertyPrices: Array<Maybe<Price>>;
    getPropertySettings: Settings;
    getSearchLocations: Array<Maybe<Location>>;
    getServiceKeys?: Maybe<Array<Maybe<ServiceKey>>>;
    getSmoobuAndLocalPropertyDetails: PropertyDetailsRemoteLocal;
    getUserBookings: Array<Maybe<Booking>>;
    getUserFavPropIds: Array<Maybe<PropertyIds>>;
    getUserFavProperties: Array<Maybe<PropertyList>>;
    getUserPassport: Passport;
    getUserProfile: UserProfile;
    getUserStatus: UserStatus;
    searchProperties: Array<Maybe<SearchResult>>;
    whoami?: Maybe<Scalars['String']>;
};

export type QueryGetAdminPropertyBySlugArgs = {
    slug: Scalars['String'];
};

export type QueryGetApartmentDetailsArgs = {
    id: Scalars['ID'];
};

export type QueryGetBookingLogsByReservationIdArgs = {
    reservationId: Scalars['ID'];
};

export type QueryGetPricesArgs = {
    propertyId: Scalars['ID'];
    smoobuId: Scalars['ID'];
};

export type QueryGetPropertyBookingsArgs = {
    propertyId: Scalars['ID'];
};

export type QueryGetPropertyByIdArgs = {
    propertyId: Scalars['ID'];
};

export type QueryGetPropertyBySlugArgs = {
    slug: Scalars['String'];
};

export type QueryGetPropertyCalendarArgs = {
    input: GetPropertyCalendarInput;
};

export type QueryGetPropertyContentArgs = {
    propertyId: Scalars['ID'];
};

export type QueryGetPropertyDetailsArgs = {
    propertyId: Scalars['ID'];
};

export type QueryGetPropertyImagesArgs = {
    propertyId: Scalars['ID'];
};

export type QueryGetPropertyLocationArgs = {
    propertyId: Scalars['ID'];
};

export type QueryGetPropertyMetaConfigsArgs = {
    smoobuId: Scalars['String'];
};

export type QueryGetPropertyPricesArgs = {
    propertyId: Scalars['ID'];
};

export type QueryGetPropertySettingsArgs = {
    propertyId: Scalars['ID'];
};

export type QueryGetSmoobuAndLocalPropertyDetailsArgs = {
    slug: Scalars['String'];
};

export type QueryGetUserBookingsArgs = {
    userId: Scalars['ID'];
};

export type QueryGetUserFavPropIdsArgs = {
    userId: Scalars['ID'];
};

export type QueryGetUserFavPropertiesArgs = {
    email: Scalars['String'];
};

export type QueryGetUserPassportArgs = {
    userId: Scalars['ID'];
};

export type QueryGetUserProfileArgs = {
    email: Scalars['String'];
};

export type QueryGetUserStatusArgs = {
    email: Scalars['String'];
};

export type QuerySearchPropertiesArgs = {
    input: PropertySearchInput;
};

export enum RecordStatus {
    Banned = 'banned',
    Disabled = 'disabled',
    Enabled = 'enabled',
    Hold = 'hold',
    PassportApproved = 'passport_approved',
    PassportNotFound = 'passport_not_found',
    PassportPending = 'passport_pending',
    PassportRejected = 'passport_rejected',
    Pending = 'pending',
    Resume = 'resume',
    Suspended = 'suspended',
    Verified = 'verified',
}

export type ResendConfirmEmailInput = {
    token: Scalars['String'];
};

export type ResetPasswordInput = {
    newPass: Scalars['String'];
    oldPass: Scalars['String'];
    token: Scalars['String'];
};

export type SaveUserPropertyInput = {
    isFav: Scalars['Boolean'];
    userId: Scalars['ID'];
};

export type SearchResult = {
    __typename?: 'SearchResult';
    address?: Maybe<Scalars['String']>;
    bath_rooms?: Maybe<Scalars['Int']>;
    bed_rooms?: Maybe<Scalars['Int']>;
    city?: Maybe<Scalars['String']>;
    country?: Maybe<Scalars['String']>;
    details?: Maybe<Details>;
    id?: Maybe<Scalars['ID']>;
    is_featured?: Maybe<Scalars['Boolean']>;
    keywords?: Maybe<Scalars['String']>;
    map?: Maybe<Scalars['Boolean']>;
    max_occupancy?: Maybe<Scalars['Int']>;
    name: Scalars['String'];
    parent_id?: Maybe<Scalars['ID']>;
    pricePerNight?: Maybe<Scalars['Int']>;
    settings?: Maybe<Settings>;
    slug?: Maybe<Scalars['String']>;
    smoobu_id?: Maybe<Scalars['ID']>;
    sort_order?: Maybe<Scalars['Int']>;
    street?: Maybe<Scalars['String']>;
    stripe_product_id?: Maybe<Scalars['ID']>;
    thumb?: Maybe<Scalars['String']>;
};

export type ServiceKey = {
    __typename?: 'ServiceKey';
    name: Scalars['String'];
    status: Scalars['String'];
    value: Scalars['String'];
};

export type Settings = {
    __typename?: 'Settings';
    calendarState?: Maybe<RecordStatus>;
    is_featured?: Maybe<Scalars['Boolean']>;
    map?: Maybe<Scalars['Boolean']>;
    minimumNights?: Maybe<Scalars['Int']>;
    slug?: Maybe<Scalars['String']>;
    sort_order?: Maybe<Scalars['Int']>;
    status?: Maybe<Scalars['String']>;
    time_zone?: Maybe<Scalars['String']>;
};

export type SettingsInput = {
    is_featured?: InputMaybe<Scalars['Boolean']>;
    map?: InputMaybe<Scalars['Boolean']>;
    sort_order?: InputMaybe<Scalars['Int']>;
    status?: InputMaybe<Scalars['String']>;
    time_zone?: InputMaybe<Scalars['String']>;
};

export enum Status {
    Error = 'ERROR',
    Failed = 'FAILED',
    Success = 'SUCCESS',
}

export type StripeProduct = {
    __typename?: 'StripeProduct';
    active?: Maybe<Scalars['Boolean']>;
    created?: Maybe<Scalars['Float']>;
    description?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    images?: Maybe<Array<Maybe<Scalars['String']>>>;
    livemode?: Maybe<Scalars['Boolean']>;
    metadata?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    object?: Maybe<Scalars['String']>;
    status?: Maybe<Scalars['String']>;
    updated?: Maybe<Scalars['Float']>;
};

export type StripeProductInput = {
    description?: InputMaybe<Scalars['String']>;
    name: Scalars['String'];
    propertyId: Scalars['ID'];
    propertySmoobuId: Scalars['ID'];
    publicUrl?: InputMaybe<Scalars['String']>;
    status?: InputMaybe<Scalars['String']>;
    thumbnail?: InputMaybe<Scalars['String']>;
};

export type Subscription = {
    __typename?: 'Subscription';
    whoami?: Maybe<Scalars['String']>;
};

export type UpdateBookingInput = {
    id?: InputMaybe<Scalars['ID']>;
    status?: InputMaybe<Scalars['String']>;
};

export type UpdatePassportInput = {
    back_image?: InputMaybe<Scalars['String']>;
    front_image?: InputMaybe<Scalars['String']>;
    image?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<Scalars['String']>;
    number?: InputMaybe<Scalars['String']>;
    user_id: Scalars['ID'];
};

export type UpdatePassportStatusInput = {
    id: Scalars['ID'];
    status: RecordStatus;
    user_id: Scalars['ID'];
};

export type UpdatePriceTypeInput = {
    priceType?: InputMaybe<PriceTypeInput>;
    priceTypeId: Scalars['ID'];
};

export type UpdatePropertyInput = {
    description?: InputMaybe<Scalars['String']>;
    details?: InputMaybe<DetailsInput>;
    id?: InputMaybe<Scalars['ID']>;
    images?: InputMaybe<Array<InputMaybe<ImageInput>>>;
    location?: InputMaybe<LocationInput>;
    parent_id?: InputMaybe<Scalars['ID']>;
    prices?: InputMaybe<PriceInput>;
    settings?: InputMaybe<SettingsInput>;
    smoobu_id?: InputMaybe<Scalars['ID']>;
    stripe_product_id?: InputMaybe<Scalars['ID']>;
};

export type UpdateUserProfileInput = {
    country?: InputMaybe<Scalars['String']>;
    email: Scalars['String'];
    first_name?: InputMaybe<Scalars['String']>;
    image?: InputMaybe<Scalars['String']>;
    last_name?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<Scalars['String']>;
    phone?: InputMaybe<Scalars['String']>;
};

export type UserProfile = {
    __typename?: 'UserProfile';
    country: Scalars['String'];
    email: Scalars['String'];
    first_name: Scalars['String'];
    id: Scalars['String'];
    image?: Maybe<Scalars['String']>;
    last_name: Scalars['String'];
    name?: Maybe<Scalars['String']>;
    passport?: Maybe<Passport>;
    phone: Scalars['String'];
    profile_status?: Maybe<RecordStatus>;
    status: RecordStatus;
};

export type UserStatus = {
    __typename?: 'UserStatus';
    profile_status?: Maybe<RecordStatus>;
    status: RecordStatus;
};

export type AuthenticateMutationVariables = Exact<{
    input: AuthInput;
}>;

export type AuthenticateMutation = { __typename?: 'Mutation'; authenticate: { __typename?: 'GqlResponse'; status: Status; data?: string | null; error?: string | null } };

export type CancelSmoobuDxbBookingMutationVariables = Exact<{
    input: CancelBookingInput;
}>;

export type CancelSmoobuDxbBookingMutation = {
    __typename?: 'Mutation';
    cancelSmoobuDxbBooking: { __typename?: 'GqlResponse'; data?: string | null; error?: string | null; status: Status };
};

export type ChangePasswordMutationVariables = Exact<{
    input: ChangePasswordInput;
}>;

export type ChangePasswordMutation = { __typename?: 'Mutation'; changePassword: { __typename?: 'GqlResponse'; data?: string | null; error?: string | null; status: Status } };

export type SendInquiryEmailMutationVariables = Exact<{
    input: InquiryInput;
}>;

export type SendInquiryEmailMutation = { __typename?: 'Mutation'; sendInquiryEmail: { __typename?: 'GqlResponse'; data?: string | null; error?: string | null; status: Status } };

export type CreateBookingPaymentSessionMutationVariables = Exact<{
    input: CreateBookingPaymentInput;
}>;

export type CreateBookingPaymentSessionMutation = {
    __typename?: 'Mutation';
    createBookingPaymentSession: { __typename?: 'GqlResponse'; data?: string | null; error?: string | null; status: Status };
};

export type ForgetPasswordMutationVariables = Exact<{
    input: ForgetPasswordInput;
}>;

export type ForgetPasswordMutation = { __typename?: 'Mutation'; forgetPassword: { __typename?: 'GqlResponse'; data?: string | null; error?: string | null; status: Status } };

export type GetAllCarousalSlidesQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllCarousalSlidesQuery = {
    __typename?: 'Query';
    getAllCarousalSlides?: Array<{
        __typename?: 'CarousalSlides';
        id?: string | null;
        title?: string | null;
        heading?: string | null;
        description?: string | null;
        background_image_url?: string | null;
        target_url?: string | null;
        status: string;
    } | null> | null;
};

export type GetFeaturedPropertiesQueryVariables = Exact<{ [key: string]: never }>;

export type GetFeaturedPropertiesQuery = {
    __typename?: 'Query';
    getFeaturedProperties: Array<{
        __typename?: 'FeaturedPropertyList';
        id?: string | null;
        parent_id?: string | null;
        smoobu_id?: string | null;
        name: string;
        description?: string | null;
        details?: { __typename?: 'Details'; max_occupancy?: number | null; bed_rooms?: number | null; bath_rooms?: number | null; size?: number | null } | null;
        images?: Array<{ __typename?: 'Image'; status?: string | null; src?: string | null; type?: string | null } | null> | null;
        location?: { __typename?: 'Location'; address?: string | null; city?: string | null; country?: string | null; state?: string | null; street?: string | null } | null;
        settings?: {
            __typename?: 'Settings';
            status?: string | null;
            sort_order?: number | null;
            is_featured?: boolean | null;
            slug?: string | null;
            minimumNights?: number | null;
        } | null;
        priceDetails?: { __typename?: 'PriceDetails'; pricePerNight?: number | null } | null;
    } | null>;
};

export type GetMaxGuestCountQueryVariables = Exact<{ [key: string]: never }>;

export type GetMaxGuestCountQuery = { __typename?: 'Query'; getMaxGuestCount: { __typename?: 'GuestCount'; max?: number | null } };

export type GetPricesQueryVariables = Exact<{
    propertyId: Scalars['ID'];
    smoobuId: Scalars['ID'];
}>;

export type GetPricesQuery = {
    __typename?: 'Query';
    getPrices: {
        __typename?: 'PropertyPrices';
        dxbstay?: Array<{
            __typename?: 'Price';
            amount?: number | null;
            priceType?: { __typename?: 'PriceType'; name?: string | null; condition?: string | null; conditional?: boolean | null } | null;
        } | null> | null;
        smoobu?: Array<{
            __typename?: 'PropertyCalendar';
            available?: boolean | null;
            blocked?: boolean | null;
            date?: string | null;
            min_length_of_stay?: number | null;
            price?: number | null;
        } | null> | null;
    };
};

export type GetPropertiesQueryVariables = Exact<{ [key: string]: never }>;

export type GetPropertiesQuery = {
    __typename?: 'Query';
    getProperties: Array<{
        __typename?: 'PropertyList';
        id?: string | null;
        parent_id?: string | null;
        smoobu_id?: string | null;
        name: string;
        description?: string | null;
        details?: {
            __typename?: 'Details';
            size?: number | null;
            max_occupancy?: number | null;
            bath_rooms?: number | null;
            bed_rooms?: number | null;
            child_beds?: number | null;
            couches?: number | null;
            double_beds?: number | null;
            king_size_beds?: number | null;
            queen_size_beds?: number | null;
            single_beds?: number | null;
            sofa_beds?: number | null;
            amenities?: Array<string | null> | null;
            type?: string | null;
        } | null;
        images?: Array<{
            __typename?: 'Image';
            id?: string | null;
            position?: number | null;
            title?: string | null;
            type?: string | null;
            src?: string | null;
            alt?: string | null;
            width?: number | null;
            height?: number | null;
            status?: string | null;
        } | null> | null;
        location?: {
            __typename?: 'Location';
            street?: string | null;
            zip?: string | null;
            state?: string | null;
            longitude?: number | null;
            latitude?: number | null;
            description?: string | null;
            country?: string | null;
            city?: string | null;
            address?: string | null;
            keywords?: string | null;
        } | null;
        settings?: {
            __typename?: 'Settings';
            time_zone?: string | null;
            status?: string | null;
            sort_order?: number | null;
            map?: boolean | null;
            is_featured?: boolean | null;
            slug?: string | null;
            calendarState?: RecordStatus | null;
            minimumNights?: number | null;
        } | null;
        priceDetails?: { __typename?: 'PriceDetails'; pricePerNight?: number | null } | null;
    } | null>;
};

export type GetPropertyBySlugQueryVariables = Exact<{
    slug: Scalars['String'];
}>;

export type GetPropertyBySlugQuery = {
    __typename?: 'Query';
    getPropertyBySlug: {
        __typename?: 'PropertyDetails';
        id?: string | null;
        parent_id?: string | null;
        smoobu_id?: string | null;
        name: string;
        description?: string | null;
        details?: {
            __typename?: 'Details';
            bath_rooms?: number | null;
            amenities?: Array<string | null> | null;
            bed_rooms?: number | null;
            child_beds?: number | null;
            couches?: number | null;
            king_size_beds?: number | null;
            double_beds?: number | null;
            max_occupancy?: number | null;
            queen_size_beds?: number | null;
            single_beds?: number | null;
            size?: number | null;
            sofa_beds?: number | null;
            type?: string | null;
        } | null;
        images?: Array<{
            __typename?: 'Image';
            id?: string | null;
            position?: number | null;
            title?: string | null;
            type?: string | null;
            src?: string | null;
            alt?: string | null;
            width?: number | null;
            height?: number | null;
            status?: string | null;
        } | null> | null;
        location?: {
            __typename?: 'Location';
            zip?: string | null;
            street?: string | null;
            state?: string | null;
            longitude?: number | null;
            latitude?: number | null;
            id?: string | null;
            description?: string | null;
            country?: string | null;
            city?: string | null;
            address?: string | null;
            keywords?: string | null;
        } | null;
        priceDetails?: { __typename?: 'PriceDetails'; pricePerNight?: number | null } | null;
        settings?: {
            __typename?: 'Settings';
            time_zone?: string | null;
            status?: string | null;
            sort_order?: number | null;
            map?: boolean | null;
            slug?: string | null;
            is_featured?: boolean | null;
            calendarState?: RecordStatus | null;
            minimumNights?: number | null;
        } | null;
    };
};

export type GetPropertyByIdQueryVariables = Exact<{
    propertyId: Scalars['ID'];
}>;

export type GetPropertyByIdQuery = {
    __typename?: 'Query';
    getPropertyById: {
        __typename?: 'PropertyDetails';
        id?: string | null;
        parent_id?: string | null;
        smoobu_id?: string | null;
        name: string;
        description?: string | null;
        details?: {
            __typename?: 'Details';
            bath_rooms?: number | null;
            amenities?: Array<string | null> | null;
            bed_rooms?: number | null;
            child_beds?: number | null;
            couches?: number | null;
            king_size_beds?: number | null;
            double_beds?: number | null;
            max_occupancy?: number | null;
            queen_size_beds?: number | null;
            single_beds?: number | null;
            size?: number | null;
            sofa_beds?: number | null;
            type?: string | null;
        } | null;
        images?: Array<{
            __typename?: 'Image';
            id?: string | null;
            position?: number | null;
            title?: string | null;
            type?: string | null;
            src?: string | null;
            alt?: string | null;
            width?: number | null;
            height?: number | null;
            status?: string | null;
        } | null> | null;
        location?: {
            __typename?: 'Location';
            zip?: string | null;
            street?: string | null;
            state?: string | null;
            longitude?: number | null;
            latitude?: number | null;
            id?: string | null;
            description?: string | null;
            country?: string | null;
            city?: string | null;
            address?: string | null;
        } | null;
        priceDetails?: { __typename?: 'PriceDetails'; pricePerNight?: number | null } | null;
        settings?: {
            __typename?: 'Settings';
            time_zone?: string | null;
            status?: string | null;
            sort_order?: number | null;
            map?: boolean | null;
            is_featured?: boolean | null;
            slug?: string | null;
            calendarState?: RecordStatus | null;
            minimumNights?: number | null;
        } | null;
    };
};

export type GetAllSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllSettingsQuery = { __typename?: 'Query'; getAllSettings: Array<{ __typename?: 'Settings'; slug?: string | null } | null> };

export type GetSearchLocationsQueryVariables = Exact<{ [key: string]: never }>;

export type GetSearchLocationsQuery = {
    __typename?: 'Query';
    getSearchLocations: Array<{
        __typename?: 'Location';
        id?: string | null;
        zip?: string | null;
        state?: string | null;
        keywords?: string | null;
        street?: string | null;
        city?: string | null;
        address?: string | null;
        description?: string | null;
        country?: string | null;
        latitude?: number | null;
        longitude?: number | null;
    } | null>;
};

export type GetServiceKeysQueryVariables = Exact<{ [key: string]: never }>;

export type GetServiceKeysQuery = { __typename?: 'Query'; getServiceKeys?: Array<{ __typename?: 'ServiceKey'; name: string; value: string; status: string } | null> | null };

export type GetUserBookingsQueryVariables = Exact<{
    userId: Scalars['ID'];
}>;

export type GetUserBookingsQuery = {
    __typename?: 'Query';
    getUserBookings: Array<{
        __typename?: 'Booking';
        id?: string | null;
        reservation_id?: string | null;
        start_date?: string | null;
        end_date?: string | null;
        status?: string | null;
        guests?: number | null;
        dxb_stay_price_ids?: string | null;
        price_per_night?: number | null;
        total_nights?: number | null;
        total_price?: number | null;
        grand_total_price?: number | null;
        property?: {
            __typename?: 'Property';
            id?: string | null;
            smoobu_id?: string | null;
            name: string;
            description?: string | null;
            details?: { __typename?: 'Details'; bath_rooms?: number | null; bed_rooms?: number | null } | null;
            images?: Array<{ __typename?: 'Image'; type?: string | null; src?: string | null } | null> | null;
            location?: { __typename?: 'Location'; address?: string | null } | null;
            prices?: Array<{ __typename?: 'Price'; amount?: number | null; priceType?: { __typename?: 'PriceType'; name?: string | null } | null } | null> | null;
        } | null;
    } | null>;
};

export type GetUserFavPropIdsQueryVariables = Exact<{
    userId: Scalars['ID'];
}>;

export type GetUserFavPropIdsQuery = { __typename?: 'Query'; getUserFavPropIds: Array<{ __typename?: 'PropertyIDS'; property_id: string } | null> };

export type GetUserFavPropertiesQueryVariables = Exact<{
    email: Scalars['String'];
}>;

export type GetUserFavPropertiesQuery = {
    __typename?: 'Query';
    getUserFavProperties: Array<{
        __typename?: 'PropertyList';
        id?: string | null;
        parent_id?: string | null;
        smoobu_id?: string | null;
        name: string;
        description?: string | null;
        details?: {
            __typename?: 'Details';
            amenities?: Array<string | null> | null;
            bath_rooms?: number | null;
            bed_rooms?: number | null;
            child_beds?: number | null;
            couches?: number | null;
            double_beds?: number | null;
            king_size_beds?: number | null;
            max_occupancy?: number | null;
            queen_size_beds?: number | null;
            single_beds?: number | null;
            size?: number | null;
            sofa_beds?: number | null;
            type?: string | null;
        } | null;
        images?: Array<{
            __typename?: 'Image';
            id?: string | null;
            position?: number | null;
            title?: string | null;
            type?: string | null;
            src?: string | null;
            alt?: string | null;
            width?: number | null;
            height?: number | null;
            status?: string | null;
        } | null> | null;
        location?: {
            __typename?: 'Location';
            zip?: string | null;
            street?: string | null;
            state?: string | null;
            longitude?: number | null;
            latitude?: number | null;
            keywords?: string | null;
            id?: string | null;
            description?: string | null;
            country?: string | null;
            city?: string | null;
            address?: string | null;
        } | null;
        priceDetails?: { __typename?: 'PriceDetails'; pricePerNight?: number | null } | null;
        settings?: {
            __typename?: 'Settings';
            time_zone?: string | null;
            status?: string | null;
            sort_order?: number | null;
            slug?: string | null;
            map?: boolean | null;
            is_featured?: boolean | null;
            calendarState?: RecordStatus | null;
            minimumNights?: number | null;
        } | null;
    } | null>;
};

export type GetUserProfileQueryVariables = Exact<{
    email: Scalars['String'];
}>;

export type GetUserProfileQuery = {
    __typename?: 'Query';
    getUserProfile: {
        __typename?: 'UserProfile';
        id: string;
        country: string;
        email: string;
        image?: string | null;
        name?: string | null;
        first_name: string;
        last_name: string;
        phone: string;
        profile_status?: RecordStatus | null;
        status: RecordStatus;
        passport?: { __typename?: 'Passport'; image?: string | null } | null;
    };
};

export type GetUserStatusQueryVariables = Exact<{
    email: Scalars['String'];
}>;

export type GetUserStatusQuery = { __typename?: 'Query'; getUserStatus: { __typename?: 'UserStatus'; profile_status?: RecordStatus | null; status: RecordStatus } };

export type RegisterUserMutationVariables = Exact<{
    input: CreateUserInput;
}>;

export type RegisterUserMutation = { __typename?: 'Mutation'; registerUser: { __typename?: 'GqlResponse'; status: Status; data?: string | null; error?: string | null } };

export type RemoveUserFavPropertyMutationVariables = Exact<{
    propertyId: Scalars['ID'];
    userId: Scalars['ID'];
}>;

export type RemoveUserFavPropertyMutation = {
    __typename?: 'Mutation';
    removeUserFavProperty: { __typename?: 'GqlResponse'; data?: string | null; error?: string | null; status: Status };
};

export type ResendConfirmEmailMutationVariables = Exact<{
    input: ResendConfirmEmailInput;
}>;

export type ResendConfirmEmailMutation = {
    __typename?: 'Mutation';
    resendConfirmEmail: { __typename?: 'GqlResponse'; data?: string | null; error?: string | null; status: Status };
};

export type ResetPasswordMutationVariables = Exact<{
    input: ResetPasswordInput;
}>;

export type ResetPasswordMutation = { __typename?: 'Mutation'; resetPassword: { __typename?: 'GqlResponse'; data?: string | null; error?: string | null; status: Status } };

export type SaveUserFavPropertyMutationVariables = Exact<{
    propertyId: Scalars['ID'];
    userId: Scalars['ID'];
}>;

export type SaveUserFavPropertyMutation = {
    __typename?: 'Mutation';
    saveUserFavProperty: { __typename?: 'GqlResponse'; data?: string | null; error?: string | null; status: Status };
};

export type SearchPropertiesQueryVariables = Exact<{
    input: PropertySearchInput;
}>;

export type SearchPropertiesQuery = {
    __typename?: 'Query';
    searchProperties: Array<{
        __typename?: 'SearchResult';
        id?: string | null;
        parent_id?: string | null;
        smoobu_id?: string | null;
        name: string;
        bath_rooms?: number | null;
        bed_rooms?: number | null;
        max_occupancy?: number | null;
        thumb?: string | null;
        street?: string | null;
        country?: string | null;
        city?: string | null;
        address?: string | null;
        pricePerNight?: number | null;
        sort_order?: number | null;
        map?: boolean | null;
        slug?: string | null;
        is_featured?: boolean | null;
        keywords?: string | null;
        settings?: { __typename?: 'Settings'; minimumNights?: number | null } | null;
        details?: { __typename?: 'Details'; size?: number | null } | null;
    } | null>;
};

export type CreateNewPassportMutationVariables = Exact<{
    input: CreatePassportInput;
}>;

export type CreateNewPassportMutation = { __typename?: 'Mutation'; createNewPassport: { __typename?: 'GqlResponse'; data?: string | null; error?: string | null; status: Status } };

export type UpdateUserAvatarMutationVariables = Exact<{
    userId: Scalars['ID'];
    image: Scalars['String'];
}>;

export type UpdateUserAvatarMutation = { __typename?: 'Mutation'; updateUserAvatar: { __typename?: 'GqlResponse'; data?: string | null; error?: string | null; status: Status } };

export type UpdateUserProfileMutationVariables = Exact<{
    input: UpdateUserProfileInput;
}>;

export type UpdateUserProfileMutation = { __typename?: 'Mutation'; updateUserProfile: { __typename?: 'GqlResponse'; data?: string | null; status: Status; error?: string | null } };

export const AuthenticateDocument = gql`
    mutation authenticate($input: AuthInput!) {
        authenticate(input: $input) {
            status
            data
            error
        }
    }
`;
export type AuthenticateMutationFn = Apollo.MutationFunction<AuthenticateMutation, AuthenticateMutationVariables>;

/**
 * __useAuthenticateMutation__
 *
 * To run a mutation, you first call `useAuthenticateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthenticateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authenticateMutation, { data, loading, error }] = useAuthenticateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAuthenticateMutation(baseOptions?: Apollo.MutationHookOptions<AuthenticateMutation, AuthenticateMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<AuthenticateMutation, AuthenticateMutationVariables>(AuthenticateDocument, options);
}
export type AuthenticateMutationHookResult = ReturnType<typeof useAuthenticateMutation>;
export type AuthenticateMutationResult = Apollo.MutationResult<AuthenticateMutation>;
export type AuthenticateMutationOptions = Apollo.BaseMutationOptions<AuthenticateMutation, AuthenticateMutationVariables>;
export const CancelSmoobuDxbBookingDocument = gql`
    mutation CancelSmoobuDxbBooking($input: CancelBookingInput!) {
        cancelSmoobuDxbBooking(input: $input) {
            data
            error
            status
        }
    }
`;
export type CancelSmoobuDxbBookingMutationFn = Apollo.MutationFunction<CancelSmoobuDxbBookingMutation, CancelSmoobuDxbBookingMutationVariables>;

/**
 * __useCancelSmoobuDxbBookingMutation__
 *
 * To run a mutation, you first call `useCancelSmoobuDxbBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSmoobuDxbBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSmoobuDxbBookingMutation, { data, loading, error }] = useCancelSmoobuDxbBookingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelSmoobuDxbBookingMutation(baseOptions?: Apollo.MutationHookOptions<CancelSmoobuDxbBookingMutation, CancelSmoobuDxbBookingMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CancelSmoobuDxbBookingMutation, CancelSmoobuDxbBookingMutationVariables>(CancelSmoobuDxbBookingDocument, options);
}
export type CancelSmoobuDxbBookingMutationHookResult = ReturnType<typeof useCancelSmoobuDxbBookingMutation>;
export type CancelSmoobuDxbBookingMutationResult = Apollo.MutationResult<CancelSmoobuDxbBookingMutation>;
export type CancelSmoobuDxbBookingMutationOptions = Apollo.BaseMutationOptions<CancelSmoobuDxbBookingMutation, CancelSmoobuDxbBookingMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation ChangePassword($input: ChangePasswordInput!) {
        changePassword(input: $input) {
            data
            error
            status
        }
    }
`;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
}
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const SendInquiryEmailDocument = gql`
    mutation sendInquiryEmail($input: InquiryInput!) {
        sendInquiryEmail(input: $input) {
            data
            error
            status
        }
    }
`;
export type SendInquiryEmailMutationFn = Apollo.MutationFunction<SendInquiryEmailMutation, SendInquiryEmailMutationVariables>;

/**
 * __useSendInquiryEmailMutation__
 *
 * To run a mutation, you first call `useSendInquiryEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendInquiryEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendInquiryEmailMutation, { data, loading, error }] = useSendInquiryEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendInquiryEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendInquiryEmailMutation, SendInquiryEmailMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<SendInquiryEmailMutation, SendInquiryEmailMutationVariables>(SendInquiryEmailDocument, options);
}
export type SendInquiryEmailMutationHookResult = ReturnType<typeof useSendInquiryEmailMutation>;
export type SendInquiryEmailMutationResult = Apollo.MutationResult<SendInquiryEmailMutation>;
export type SendInquiryEmailMutationOptions = Apollo.BaseMutationOptions<SendInquiryEmailMutation, SendInquiryEmailMutationVariables>;
export const CreateBookingPaymentSessionDocument = gql`
    mutation CreateBookingPaymentSession($input: CreateBookingPaymentInput!) {
        createBookingPaymentSession(input: $input) {
            data
            error
            status
        }
    }
`;
export type CreateBookingPaymentSessionMutationFn = Apollo.MutationFunction<CreateBookingPaymentSessionMutation, CreateBookingPaymentSessionMutationVariables>;

/**
 * __useCreateBookingPaymentSessionMutation__
 *
 * To run a mutation, you first call `useCreateBookingPaymentSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBookingPaymentSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBookingPaymentSessionMutation, { data, loading, error }] = useCreateBookingPaymentSessionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBookingPaymentSessionMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateBookingPaymentSessionMutation, CreateBookingPaymentSessionMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreateBookingPaymentSessionMutation, CreateBookingPaymentSessionMutationVariables>(CreateBookingPaymentSessionDocument, options);
}
export type CreateBookingPaymentSessionMutationHookResult = ReturnType<typeof useCreateBookingPaymentSessionMutation>;
export type CreateBookingPaymentSessionMutationResult = Apollo.MutationResult<CreateBookingPaymentSessionMutation>;
export type CreateBookingPaymentSessionMutationOptions = Apollo.BaseMutationOptions<CreateBookingPaymentSessionMutation, CreateBookingPaymentSessionMutationVariables>;
export const ForgetPasswordDocument = gql`
    mutation ForgetPassword($input: ForgetPasswordInput!) {
        forgetPassword(input: $input) {
            data
            error
            status
        }
    }
`;
export type ForgetPasswordMutationFn = Apollo.MutationFunction<ForgetPasswordMutation, ForgetPasswordMutationVariables>;

/**
 * __useForgetPasswordMutation__
 *
 * To run a mutation, you first call `useForgetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgetPasswordMutation, { data, loading, error }] = useForgetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useForgetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgetPasswordMutation, ForgetPasswordMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<ForgetPasswordMutation, ForgetPasswordMutationVariables>(ForgetPasswordDocument, options);
}
export type ForgetPasswordMutationHookResult = ReturnType<typeof useForgetPasswordMutation>;
export type ForgetPasswordMutationResult = Apollo.MutationResult<ForgetPasswordMutation>;
export type ForgetPasswordMutationOptions = Apollo.BaseMutationOptions<ForgetPasswordMutation, ForgetPasswordMutationVariables>;
export const GetAllCarousalSlidesDocument = gql`
    query GetAllCarousalSlides {
        getAllCarousalSlides {
            id
            title
            heading
            description
            background_image_url
            target_url
            status
        }
    }
`;

/**
 * __useGetAllCarousalSlidesQuery__
 *
 * To run a query within a React component, call `useGetAllCarousalSlidesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCarousalSlidesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCarousalSlidesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllCarousalSlidesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllCarousalSlidesQuery, GetAllCarousalSlidesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetAllCarousalSlidesQuery, GetAllCarousalSlidesQueryVariables>(GetAllCarousalSlidesDocument, options);
}
export function useGetAllCarousalSlidesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllCarousalSlidesQuery, GetAllCarousalSlidesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetAllCarousalSlidesQuery, GetAllCarousalSlidesQueryVariables>(GetAllCarousalSlidesDocument, options);
}
export type GetAllCarousalSlidesQueryHookResult = ReturnType<typeof useGetAllCarousalSlidesQuery>;
export type GetAllCarousalSlidesLazyQueryHookResult = ReturnType<typeof useGetAllCarousalSlidesLazyQuery>;
export type GetAllCarousalSlidesQueryResult = Apollo.QueryResult<GetAllCarousalSlidesQuery, GetAllCarousalSlidesQueryVariables>;
export const GetFeaturedPropertiesDocument = gql`
    query GetFeaturedProperties {
        getFeaturedProperties {
            id
            parent_id
            smoobu_id
            name
            description
            details {
                max_occupancy
                bed_rooms
                bath_rooms
                size
            }
            images {
                status
                src
                type
            }
            location {
                address
                city
                country
                state
                street
            }
            settings {
                status
                sort_order
                is_featured
                slug
                minimumNights
            }
            priceDetails {
                pricePerNight
            }
        }
    }
`;

/**
 * __useGetFeaturedPropertiesQuery__
 *
 * To run a query within a React component, call `useGetFeaturedPropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeaturedPropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeaturedPropertiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFeaturedPropertiesQuery(baseOptions?: Apollo.QueryHookOptions<GetFeaturedPropertiesQuery, GetFeaturedPropertiesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetFeaturedPropertiesQuery, GetFeaturedPropertiesQueryVariables>(GetFeaturedPropertiesDocument, options);
}
export function useGetFeaturedPropertiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFeaturedPropertiesQuery, GetFeaturedPropertiesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetFeaturedPropertiesQuery, GetFeaturedPropertiesQueryVariables>(GetFeaturedPropertiesDocument, options);
}
export type GetFeaturedPropertiesQueryHookResult = ReturnType<typeof useGetFeaturedPropertiesQuery>;
export type GetFeaturedPropertiesLazyQueryHookResult = ReturnType<typeof useGetFeaturedPropertiesLazyQuery>;
export type GetFeaturedPropertiesQueryResult = Apollo.QueryResult<GetFeaturedPropertiesQuery, GetFeaturedPropertiesQueryVariables>;
export const GetMaxGuestCountDocument = gql`
    query GetMaxGuestCount {
        getMaxGuestCount {
            max
        }
    }
`;

/**
 * __useGetMaxGuestCountQuery__
 *
 * To run a query within a React component, call `useGetMaxGuestCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMaxGuestCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMaxGuestCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMaxGuestCountQuery(baseOptions?: Apollo.QueryHookOptions<GetMaxGuestCountQuery, GetMaxGuestCountQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetMaxGuestCountQuery, GetMaxGuestCountQueryVariables>(GetMaxGuestCountDocument, options);
}
export function useGetMaxGuestCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMaxGuestCountQuery, GetMaxGuestCountQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetMaxGuestCountQuery, GetMaxGuestCountQueryVariables>(GetMaxGuestCountDocument, options);
}
export type GetMaxGuestCountQueryHookResult = ReturnType<typeof useGetMaxGuestCountQuery>;
export type GetMaxGuestCountLazyQueryHookResult = ReturnType<typeof useGetMaxGuestCountLazyQuery>;
export type GetMaxGuestCountQueryResult = Apollo.QueryResult<GetMaxGuestCountQuery, GetMaxGuestCountQueryVariables>;
export const GetPricesDocument = gql`
    query GetPrices($propertyId: ID!, $smoobuId: ID!) {
        getPrices(propertyId: $propertyId, smoobuId: $smoobuId) {
            dxbstay {
                amount
                priceType {
                    name
                    condition
                    conditional
                }
            }
            smoobu {
                available
                blocked
                date
                min_length_of_stay
                price
            }
        }
    }
`;

/**
 * __useGetPricesQuery__
 *
 * To run a query within a React component, call `useGetPricesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPricesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPricesQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      smoobuId: // value for 'smoobuId'
 *   },
 * });
 */
export function useGetPricesQuery(baseOptions: Apollo.QueryHookOptions<GetPricesQuery, GetPricesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetPricesQuery, GetPricesQueryVariables>(GetPricesDocument, options);
}
export function useGetPricesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPricesQuery, GetPricesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetPricesQuery, GetPricesQueryVariables>(GetPricesDocument, options);
}
export type GetPricesQueryHookResult = ReturnType<typeof useGetPricesQuery>;
export type GetPricesLazyQueryHookResult = ReturnType<typeof useGetPricesLazyQuery>;
export type GetPricesQueryResult = Apollo.QueryResult<GetPricesQuery, GetPricesQueryVariables>;
export const GetPropertiesDocument = gql`
    query GetProperties {
        getProperties {
            id
            parent_id
            smoobu_id
            name
            description
            details {
                size
                max_occupancy
                bath_rooms
                bed_rooms
                child_beds
                couches
                double_beds
                king_size_beds
                queen_size_beds
                single_beds
                sofa_beds
                amenities
                type
            }
            images {
                id
                position
                title
                type
                src
                alt
                width
                height
                status
            }
            location {
                street
                zip
                state
                longitude
                latitude
                description
                country
                city
                address
                keywords
            }
            settings {
                time_zone
                status
                sort_order
                map
                is_featured
                slug
                calendarState
                minimumNights
            }
            priceDetails {
                pricePerNight
            }
        }
    }
`;

/**
 * __useGetPropertiesQuery__
 *
 * To run a query within a React component, call `useGetPropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropertiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPropertiesQuery(baseOptions?: Apollo.QueryHookOptions<GetPropertiesQuery, GetPropertiesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetPropertiesQuery, GetPropertiesQueryVariables>(GetPropertiesDocument, options);
}
export function useGetPropertiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPropertiesQuery, GetPropertiesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetPropertiesQuery, GetPropertiesQueryVariables>(GetPropertiesDocument, options);
}
export type GetPropertiesQueryHookResult = ReturnType<typeof useGetPropertiesQuery>;
export type GetPropertiesLazyQueryHookResult = ReturnType<typeof useGetPropertiesLazyQuery>;
export type GetPropertiesQueryResult = Apollo.QueryResult<GetPropertiesQuery, GetPropertiesQueryVariables>;
export const GetPropertyBySlugDocument = gql`
    query GetPropertyBySlug($slug: String!) {
        getPropertyBySlug(slug: $slug) {
            id
            parent_id
            smoobu_id
            name
            description
            details {
                bath_rooms
                amenities
                bed_rooms
                child_beds
                couches
                king_size_beds
                double_beds
                max_occupancy
                queen_size_beds
                single_beds
                size
                sofa_beds
                type
            }
            images {
                id
                position
                title
                type
                src
                alt
                width
                height
                status
            }
            location {
                zip
                street
                state
                longitude
                latitude
                id
                description
                country
                city
                address
                keywords
            }
            priceDetails {
                pricePerNight
            }
            settings {
                time_zone
                status
                sort_order
                map
                slug
                is_featured
                calendarState
                minimumNights
            }
        }
    }
`;

/**
 * __useGetPropertyBySlugQuery__
 *
 * To run a query within a React component, call `useGetPropertyBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropertyBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropertyBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetPropertyBySlugQuery(baseOptions: Apollo.QueryHookOptions<GetPropertyBySlugQuery, GetPropertyBySlugQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetPropertyBySlugQuery, GetPropertyBySlugQueryVariables>(GetPropertyBySlugDocument, options);
}
export function useGetPropertyBySlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPropertyBySlugQuery, GetPropertyBySlugQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetPropertyBySlugQuery, GetPropertyBySlugQueryVariables>(GetPropertyBySlugDocument, options);
}
export type GetPropertyBySlugQueryHookResult = ReturnType<typeof useGetPropertyBySlugQuery>;
export type GetPropertyBySlugLazyQueryHookResult = ReturnType<typeof useGetPropertyBySlugLazyQuery>;
export type GetPropertyBySlugQueryResult = Apollo.QueryResult<GetPropertyBySlugQuery, GetPropertyBySlugQueryVariables>;
export const GetPropertyByIdDocument = gql`
    query GetPropertyById($propertyId: ID!) {
        getPropertyById(propertyId: $propertyId) {
            id
            parent_id
            smoobu_id
            name
            description
            details {
                bath_rooms
                amenities
                bed_rooms
                child_beds
                couches
                king_size_beds
                double_beds
                max_occupancy
                queen_size_beds
                single_beds
                size
                sofa_beds
                type
            }
            images {
                id
                position
                title
                type
                src
                alt
                width
                height
                status
            }
            location {
                zip
                street
                state
                longitude
                latitude
                id
                description
                country
                city
                address
            }
            priceDetails {
                pricePerNight
            }
            settings {
                time_zone
                status
                sort_order
                map
                is_featured
                slug
                calendarState
                minimumNights
            }
        }
    }
`;

/**
 * __useGetPropertyByIdQuery__
 *
 * To run a query within a React component, call `useGetPropertyByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropertyByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropertyByIdQuery({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *   },
 * });
 */
export function useGetPropertyByIdQuery(baseOptions: Apollo.QueryHookOptions<GetPropertyByIdQuery, GetPropertyByIdQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetPropertyByIdQuery, GetPropertyByIdQueryVariables>(GetPropertyByIdDocument, options);
}
export function useGetPropertyByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPropertyByIdQuery, GetPropertyByIdQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetPropertyByIdQuery, GetPropertyByIdQueryVariables>(GetPropertyByIdDocument, options);
}
export type GetPropertyByIdQueryHookResult = ReturnType<typeof useGetPropertyByIdQuery>;
export type GetPropertyByIdLazyQueryHookResult = ReturnType<typeof useGetPropertyByIdLazyQuery>;
export type GetPropertyByIdQueryResult = Apollo.QueryResult<GetPropertyByIdQuery, GetPropertyByIdQueryVariables>;
export const GetAllSettingsDocument = gql`
    query GetAllSettings {
        getAllSettings {
            slug
        }
    }
`;

/**
 * __useGetAllSettingsQuery__
 *
 * To run a query within a React component, call `useGetAllSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllSettingsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllSettingsQuery, GetAllSettingsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetAllSettingsQuery, GetAllSettingsQueryVariables>(GetAllSettingsDocument, options);
}
export function useGetAllSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllSettingsQuery, GetAllSettingsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetAllSettingsQuery, GetAllSettingsQueryVariables>(GetAllSettingsDocument, options);
}
export type GetAllSettingsQueryHookResult = ReturnType<typeof useGetAllSettingsQuery>;
export type GetAllSettingsLazyQueryHookResult = ReturnType<typeof useGetAllSettingsLazyQuery>;
export type GetAllSettingsQueryResult = Apollo.QueryResult<GetAllSettingsQuery, GetAllSettingsQueryVariables>;
export const GetSearchLocationsDocument = gql`
    query GetSearchLocations {
        getSearchLocations {
            id
            zip
            state
            keywords
            street
            city
            address
            description
            country
            latitude
            longitude
        }
    }
`;

/**
 * __useGetSearchLocationsQuery__
 *
 * To run a query within a React component, call `useGetSearchLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchLocationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSearchLocationsQuery(baseOptions?: Apollo.QueryHookOptions<GetSearchLocationsQuery, GetSearchLocationsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetSearchLocationsQuery, GetSearchLocationsQueryVariables>(GetSearchLocationsDocument, options);
}
export function useGetSearchLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSearchLocationsQuery, GetSearchLocationsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetSearchLocationsQuery, GetSearchLocationsQueryVariables>(GetSearchLocationsDocument, options);
}
export type GetSearchLocationsQueryHookResult = ReturnType<typeof useGetSearchLocationsQuery>;
export type GetSearchLocationsLazyQueryHookResult = ReturnType<typeof useGetSearchLocationsLazyQuery>;
export type GetSearchLocationsQueryResult = Apollo.QueryResult<GetSearchLocationsQuery, GetSearchLocationsQueryVariables>;
export const GetServiceKeysDocument = gql`
    query GetServiceKeys {
        getServiceKeys {
            name
            value
            status
        }
    }
`;

/**
 * __useGetServiceKeysQuery__
 *
 * To run a query within a React component, call `useGetServiceKeysQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServiceKeysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServiceKeysQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetServiceKeysQuery(baseOptions?: Apollo.QueryHookOptions<GetServiceKeysQuery, GetServiceKeysQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetServiceKeysQuery, GetServiceKeysQueryVariables>(GetServiceKeysDocument, options);
}
export function useGetServiceKeysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetServiceKeysQuery, GetServiceKeysQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetServiceKeysQuery, GetServiceKeysQueryVariables>(GetServiceKeysDocument, options);
}
export type GetServiceKeysQueryHookResult = ReturnType<typeof useGetServiceKeysQuery>;
export type GetServiceKeysLazyQueryHookResult = ReturnType<typeof useGetServiceKeysLazyQuery>;
export type GetServiceKeysQueryResult = Apollo.QueryResult<GetServiceKeysQuery, GetServiceKeysQueryVariables>;
export const GetUserBookingsDocument = gql`
    query GetUserBookings($userId: ID!) {
        getUserBookings(userId: $userId) {
            id
            reservation_id
            start_date
            end_date
            status
            guests
            dxb_stay_price_ids
            price_per_night
            total_nights
            total_price
            grand_total_price
            property {
                id
                smoobu_id
                name
                description
                details {
                    bath_rooms
                    bed_rooms
                }
                images {
                    type
                    src
                }
                location {
                    address
                }
                prices {
                    priceType {
                        name
                    }
                    amount
                }
            }
        }
    }
`;

/**
 * __useGetUserBookingsQuery__
 *
 * To run a query within a React component, call `useGetUserBookingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserBookingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserBookingsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserBookingsQuery(baseOptions: Apollo.QueryHookOptions<GetUserBookingsQuery, GetUserBookingsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetUserBookingsQuery, GetUserBookingsQueryVariables>(GetUserBookingsDocument, options);
}
export function useGetUserBookingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserBookingsQuery, GetUserBookingsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetUserBookingsQuery, GetUserBookingsQueryVariables>(GetUserBookingsDocument, options);
}
export type GetUserBookingsQueryHookResult = ReturnType<typeof useGetUserBookingsQuery>;
export type GetUserBookingsLazyQueryHookResult = ReturnType<typeof useGetUserBookingsLazyQuery>;
export type GetUserBookingsQueryResult = Apollo.QueryResult<GetUserBookingsQuery, GetUserBookingsQueryVariables>;
export const GetUserFavPropIdsDocument = gql`
    query GetUserFavPropIds($userId: ID!) {
        getUserFavPropIds(userId: $userId) {
            property_id
        }
    }
`;

/**
 * __useGetUserFavPropIdsQuery__
 *
 * To run a query within a React component, call `useGetUserFavPropIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserFavPropIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserFavPropIdsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserFavPropIdsQuery(baseOptions: Apollo.QueryHookOptions<GetUserFavPropIdsQuery, GetUserFavPropIdsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetUserFavPropIdsQuery, GetUserFavPropIdsQueryVariables>(GetUserFavPropIdsDocument, options);
}
export function useGetUserFavPropIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserFavPropIdsQuery, GetUserFavPropIdsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetUserFavPropIdsQuery, GetUserFavPropIdsQueryVariables>(GetUserFavPropIdsDocument, options);
}
export type GetUserFavPropIdsQueryHookResult = ReturnType<typeof useGetUserFavPropIdsQuery>;
export type GetUserFavPropIdsLazyQueryHookResult = ReturnType<typeof useGetUserFavPropIdsLazyQuery>;
export type GetUserFavPropIdsQueryResult = Apollo.QueryResult<GetUserFavPropIdsQuery, GetUserFavPropIdsQueryVariables>;
export const GetUserFavPropertiesDocument = gql`
    query GetUserFavProperties($email: String!) {
        getUserFavProperties(email: $email) {
            id
            parent_id
            smoobu_id
            name
            description
            details {
                amenities
                bath_rooms
                bed_rooms
                child_beds
                couches
                double_beds
                king_size_beds
                max_occupancy
                queen_size_beds
                single_beds
                size
                sofa_beds
                type
            }
            images {
                id
                position
                title
                type
                src
                alt
                width
                height
                status
            }
            location {
                zip
                street
                state
                longitude
                latitude
                keywords
                id
                description
                country
                city
                address
            }
            priceDetails {
                pricePerNight
            }
            settings {
                time_zone
                status
                sort_order
                slug
                map
                is_featured
                calendarState
                minimumNights
            }
        }
    }
`;

/**
 * __useGetUserFavPropertiesQuery__
 *
 * To run a query within a React component, call `useGetUserFavPropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserFavPropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserFavPropertiesQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetUserFavPropertiesQuery(baseOptions: Apollo.QueryHookOptions<GetUserFavPropertiesQuery, GetUserFavPropertiesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetUserFavPropertiesQuery, GetUserFavPropertiesQueryVariables>(GetUserFavPropertiesDocument, options);
}
export function useGetUserFavPropertiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserFavPropertiesQuery, GetUserFavPropertiesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetUserFavPropertiesQuery, GetUserFavPropertiesQueryVariables>(GetUserFavPropertiesDocument, options);
}
export type GetUserFavPropertiesQueryHookResult = ReturnType<typeof useGetUserFavPropertiesQuery>;
export type GetUserFavPropertiesLazyQueryHookResult = ReturnType<typeof useGetUserFavPropertiesLazyQuery>;
export type GetUserFavPropertiesQueryResult = Apollo.QueryResult<GetUserFavPropertiesQuery, GetUserFavPropertiesQueryVariables>;
export const GetUserProfileDocument = gql`
    query GetUserProfile($email: String!) {
        getUserProfile(email: $email) {
            id
            country
            email
            image
            name
            first_name
            last_name
            phone
            profile_status
            status
            passport {
                image
            }
        }
    }
`;

/**
 * __useGetUserProfileQuery__
 *
 * To run a query within a React component, call `useGetUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserProfileQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetUserProfileQuery(baseOptions: Apollo.QueryHookOptions<GetUserProfileQuery, GetUserProfileQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetUserProfileQuery, GetUserProfileQueryVariables>(GetUserProfileDocument, options);
}
export function useGetUserProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserProfileQuery, GetUserProfileQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetUserProfileQuery, GetUserProfileQueryVariables>(GetUserProfileDocument, options);
}
export type GetUserProfileQueryHookResult = ReturnType<typeof useGetUserProfileQuery>;
export type GetUserProfileLazyQueryHookResult = ReturnType<typeof useGetUserProfileLazyQuery>;
export type GetUserProfileQueryResult = Apollo.QueryResult<GetUserProfileQuery, GetUserProfileQueryVariables>;
export const GetUserStatusDocument = gql`
    query GetUserStatus($email: String!) {
        getUserStatus(email: $email) {
            profile_status
            status
        }
    }
`;

/**
 * __useGetUserStatusQuery__
 *
 * To run a query within a React component, call `useGetUserStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserStatusQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetUserStatusQuery(baseOptions: Apollo.QueryHookOptions<GetUserStatusQuery, GetUserStatusQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetUserStatusQuery, GetUserStatusQueryVariables>(GetUserStatusDocument, options);
}
export function useGetUserStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserStatusQuery, GetUserStatusQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetUserStatusQuery, GetUserStatusQueryVariables>(GetUserStatusDocument, options);
}
export type GetUserStatusQueryHookResult = ReturnType<typeof useGetUserStatusQuery>;
export type GetUserStatusLazyQueryHookResult = ReturnType<typeof useGetUserStatusLazyQuery>;
export type GetUserStatusQueryResult = Apollo.QueryResult<GetUserStatusQuery, GetUserStatusQueryVariables>;
export const RegisterUserDocument = gql`
    mutation registerUser($input: CreateUserInput!) {
        registerUser(input: $input) {
            status
            data
            error
        }
    }
`;
export type RegisterUserMutationFn = Apollo.MutationFunction<RegisterUserMutation, RegisterUserMutationVariables>;

/**
 * __useRegisterUserMutation__
 *
 * To run a mutation, you first call `useRegisterUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerUserMutation, { data, loading, error }] = useRegisterUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterUserMutation(baseOptions?: Apollo.MutationHookOptions<RegisterUserMutation, RegisterUserMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<RegisterUserMutation, RegisterUserMutationVariables>(RegisterUserDocument, options);
}
export type RegisterUserMutationHookResult = ReturnType<typeof useRegisterUserMutation>;
export type RegisterUserMutationResult = Apollo.MutationResult<RegisterUserMutation>;
export type RegisterUserMutationOptions = Apollo.BaseMutationOptions<RegisterUserMutation, RegisterUserMutationVariables>;
export const RemoveUserFavPropertyDocument = gql`
    mutation RemoveUserFavProperty($propertyId: ID!, $userId: ID!) {
        removeUserFavProperty(propertyId: $propertyId, userId: $userId) {
            data
            error
            status
        }
    }
`;
export type RemoveUserFavPropertyMutationFn = Apollo.MutationFunction<RemoveUserFavPropertyMutation, RemoveUserFavPropertyMutationVariables>;

/**
 * __useRemoveUserFavPropertyMutation__
 *
 * To run a mutation, you first call `useRemoveUserFavPropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserFavPropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserFavPropertyMutation, { data, loading, error }] = useRemoveUserFavPropertyMutation({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useRemoveUserFavPropertyMutation(baseOptions?: Apollo.MutationHookOptions<RemoveUserFavPropertyMutation, RemoveUserFavPropertyMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<RemoveUserFavPropertyMutation, RemoveUserFavPropertyMutationVariables>(RemoveUserFavPropertyDocument, options);
}
export type RemoveUserFavPropertyMutationHookResult = ReturnType<typeof useRemoveUserFavPropertyMutation>;
export type RemoveUserFavPropertyMutationResult = Apollo.MutationResult<RemoveUserFavPropertyMutation>;
export type RemoveUserFavPropertyMutationOptions = Apollo.BaseMutationOptions<RemoveUserFavPropertyMutation, RemoveUserFavPropertyMutationVariables>;
export const ResendConfirmEmailDocument = gql`
    mutation ResendConfirmEmail($input: ResendConfirmEmailInput!) {
        resendConfirmEmail(input: $input) {
            data
            error
            status
        }
    }
`;
export type ResendConfirmEmailMutationFn = Apollo.MutationFunction<ResendConfirmEmailMutation, ResendConfirmEmailMutationVariables>;

/**
 * __useResendConfirmEmailMutation__
 *
 * To run a mutation, you first call `useResendConfirmEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendConfirmEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendConfirmEmailMutation, { data, loading, error }] = useResendConfirmEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResendConfirmEmailMutation(baseOptions?: Apollo.MutationHookOptions<ResendConfirmEmailMutation, ResendConfirmEmailMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<ResendConfirmEmailMutation, ResendConfirmEmailMutationVariables>(ResendConfirmEmailDocument, options);
}
export type ResendConfirmEmailMutationHookResult = ReturnType<typeof useResendConfirmEmailMutation>;
export type ResendConfirmEmailMutationResult = Apollo.MutationResult<ResendConfirmEmailMutation>;
export type ResendConfirmEmailMutationOptions = Apollo.BaseMutationOptions<ResendConfirmEmailMutation, ResendConfirmEmailMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($input: ResetPasswordInput!) {
        resetPassword(input: $input) {
            data
            error
            status
        }
    }
`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
}
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const SaveUserFavPropertyDocument = gql`
    mutation SaveUserFavProperty($propertyId: ID!, $userId: ID!) {
        saveUserFavProperty(propertyId: $propertyId, userId: $userId) {
            data
            error
            status
        }
    }
`;
export type SaveUserFavPropertyMutationFn = Apollo.MutationFunction<SaveUserFavPropertyMutation, SaveUserFavPropertyMutationVariables>;

/**
 * __useSaveUserFavPropertyMutation__
 *
 * To run a mutation, you first call `useSaveUserFavPropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveUserFavPropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveUserFavPropertyMutation, { data, loading, error }] = useSaveUserFavPropertyMutation({
 *   variables: {
 *      propertyId: // value for 'propertyId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useSaveUserFavPropertyMutation(baseOptions?: Apollo.MutationHookOptions<SaveUserFavPropertyMutation, SaveUserFavPropertyMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<SaveUserFavPropertyMutation, SaveUserFavPropertyMutationVariables>(SaveUserFavPropertyDocument, options);
}
export type SaveUserFavPropertyMutationHookResult = ReturnType<typeof useSaveUserFavPropertyMutation>;
export type SaveUserFavPropertyMutationResult = Apollo.MutationResult<SaveUserFavPropertyMutation>;
export type SaveUserFavPropertyMutationOptions = Apollo.BaseMutationOptions<SaveUserFavPropertyMutation, SaveUserFavPropertyMutationVariables>;
export const SearchPropertiesDocument = gql`
    query SearchProperties($input: PropertySearchInput!) {
        searchProperties(input: $input) {
            id
            parent_id
            smoobu_id
            name
            bath_rooms
            bed_rooms
            max_occupancy
            thumb
            name
            street
            country
            city
            address
            pricePerNight
            sort_order
            map
            slug
            is_featured
            keywords
            settings {
                minimumNights
            }
            details {
                size
            }
        }
    }
`;

/**
 * __useSearchPropertiesQuery__
 *
 * To run a query within a React component, call `useSearchPropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchPropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchPropertiesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSearchPropertiesQuery(baseOptions: Apollo.QueryHookOptions<SearchPropertiesQuery, SearchPropertiesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<SearchPropertiesQuery, SearchPropertiesQueryVariables>(SearchPropertiesDocument, options);
}
export function useSearchPropertiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchPropertiesQuery, SearchPropertiesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<SearchPropertiesQuery, SearchPropertiesQueryVariables>(SearchPropertiesDocument, options);
}
export type SearchPropertiesQueryHookResult = ReturnType<typeof useSearchPropertiesQuery>;
export type SearchPropertiesLazyQueryHookResult = ReturnType<typeof useSearchPropertiesLazyQuery>;
export type SearchPropertiesQueryResult = Apollo.QueryResult<SearchPropertiesQuery, SearchPropertiesQueryVariables>;
export const CreateNewPassportDocument = gql`
    mutation CreateNewPassport($input: CreatePassportInput!) {
        createNewPassport(input: $input) {
            data
            error
            status
        }
    }
`;
export type CreateNewPassportMutationFn = Apollo.MutationFunction<CreateNewPassportMutation, CreateNewPassportMutationVariables>;

/**
 * __useCreateNewPassportMutation__
 *
 * To run a mutation, you first call `useCreateNewPassportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewPassportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewPassportMutation, { data, loading, error }] = useCreateNewPassportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNewPassportMutation(baseOptions?: Apollo.MutationHookOptions<CreateNewPassportMutation, CreateNewPassportMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreateNewPassportMutation, CreateNewPassportMutationVariables>(CreateNewPassportDocument, options);
}
export type CreateNewPassportMutationHookResult = ReturnType<typeof useCreateNewPassportMutation>;
export type CreateNewPassportMutationResult = Apollo.MutationResult<CreateNewPassportMutation>;
export type CreateNewPassportMutationOptions = Apollo.BaseMutationOptions<CreateNewPassportMutation, CreateNewPassportMutationVariables>;
export const UpdateUserAvatarDocument = gql`
    mutation UpdateUserAvatar($userId: ID!, $image: String!) {
        updateUserAvatar(userId: $userId, image: $image) {
            data
            error
            status
        }
    }
`;
export type UpdateUserAvatarMutationFn = Apollo.MutationFunction<UpdateUserAvatarMutation, UpdateUserAvatarMutationVariables>;

/**
 * __useUpdateUserAvatarMutation__
 *
 * To run a mutation, you first call `useUpdateUserAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserAvatarMutation, { data, loading, error }] = useUpdateUserAvatarMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      image: // value for 'image'
 *   },
 * });
 */
export function useUpdateUserAvatarMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserAvatarMutation, UpdateUserAvatarMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdateUserAvatarMutation, UpdateUserAvatarMutationVariables>(UpdateUserAvatarDocument, options);
}
export type UpdateUserAvatarMutationHookResult = ReturnType<typeof useUpdateUserAvatarMutation>;
export type UpdateUserAvatarMutationResult = Apollo.MutationResult<UpdateUserAvatarMutation>;
export type UpdateUserAvatarMutationOptions = Apollo.BaseMutationOptions<UpdateUserAvatarMutation, UpdateUserAvatarMutationVariables>;
export const UpdateUserProfileDocument = gql`
    mutation UpdateUserProfile($input: UpdateUserProfileInput!) {
        updateUserProfile(input: $input) {
            data
            status
            error
        }
    }
`;
export type UpdateUserProfileMutationFn = Apollo.MutationFunction<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>;

/**
 * __useUpdateUserProfileMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfileMutation, { data, loading, error }] = useUpdateUserProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>(UpdateUserProfileDocument, options);
}
export type UpdateUserProfileMutationHookResult = ReturnType<typeof useUpdateUserProfileMutation>;
export type UpdateUserProfileMutationResult = Apollo.MutationResult<UpdateUserProfileMutation>;
export type UpdateUserProfileMutationOptions = Apollo.BaseMutationOptions<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>;

export interface PossibleTypesResultData {
    possibleTypes: {
        [key: string]: string[];
    };
}
const result: PossibleTypesResultData = {
    possibleTypes: {},
};
export default result;
