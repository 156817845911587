import React                from 'react';
import { Container, Group } from '@mantine/core';
import { AppMenuButtons }   from '@layouts/Menu/App/AppMenuButtons';
import { AuthMenuButtons }  from '@layouts/Menu/Auth/AuthMenuButtons';


export const AuthMenu: React.FC = () => {
    return (
        <Container px={0}>
            <Group>

                <AppMenuButtons/>

                <AuthMenuButtons/>

            </Group>
        </Container>
    );
};
