import { atom, useAtom } from 'jotai';
import { AppRoutes }     from '@core/router/AppRoutes';
import { useLocalStorage } from '@mantine/hooks';


const lastRouteAtom = atom(AppRoutes.HOME);

/**
 *
 * This is single source of truth for the app state related data.
 *
 * */

// TODO: MOVE ALERT STUFF TO IT'S OWN PLACE
const useAppData = () => {

    // HREF FIX
    const [lastPage, setLastPage] = useLocalStorage({
            key: 'last-page'
        }
    );

    // NEED TO KEEP AN EYE ON THIS - NOT BEHAVING AFTER UPDATES
    //<editor-fold desc="LAST ROUTE">
    const [_lastRoute, _setLastRoute] = useAtom(lastRouteAtom);
    const getLastRoute = (): string => {
        return _lastRoute;
    }
    const setLastRoute = (route: string) => {
        return _setLastRoute(route);
    }
    //</editor-fold>

    return {
        getLastRoute, setLastRoute,
        setLastPage, lastPage // <---- IN ACTIVE USE
    }
}

export default useAppData;
