import { IconAlertCircle } from '@tabler/icons';
import { Group, Text }     from '@mantine/core';
import { NextLink }        from '@mantine/next';
import { AppRoutes }       from '@core/router/AppRoutes';
import React               from 'react';


export const ProfileStatusNotificationData = {
    "passport_not_found": {
        title: 'Please complete your profile.',
        color: 'blue',
        icon: <IconAlertCircle size={16}/>,
        message: <Group>
            <Text
                variant="link"
                component={NextLink}
                href={AppRoutes.ACCOUNT}
            >
                Update Passport Image
            </Text>
        </Group>
    },
    "passport_pending": {
        title: 'Profile Status',
        color: 'green',
        icon: <IconAlertCircle size={16}/>,
        message: <Group>
            Thank you for providing us with your passport copy, it is in approval process.
        </Group>
    },
    "passport_rejected": {
        title: 'Action required',
        color: 'orange',
        icon: <IconAlertCircle size={16}/>,
        message: <Group>
            Rejected due to validity of the Passport; Please upload a valid passport.
            <Text
                variant="link"
                component={NextLink}
                href={AppRoutes.ACCOUNT}
                // onClick={() => handleProfileAlert(false)}
            >
                Update Passport
            </Text>
        </Group>
    },
}
