import React                      from 'react';
import { Container, Group, Text } from '@mantine/core';
import { IconBrandInstagram }     from '@tabler/icons';
import { LinkButton }             from '@core/components/common/LinkButton';
import { AppRoutes }              from '@core/router/AppRoutes';
import { CActionIcon }            from '@core/components/common/CActionIcon';
import { CActionImage }           from '@core/components/common/CActionImage';
import { AppMetaData }            from '@core/constants/app-meta-data';
import useStyles                  from './AppFooter.styles';

import stripeDark  from '../../../../public/icons/powered-stripe-dark.svg';
import stripeLight from '../../../../public/icons/powered-stripe-light.svg';


export const AppFooter: React.FC = () => {
    const { classes } = useStyles();

    return (
        <Container className={classes.inner}>
            <Group
                spacing={40}
                className={classes.links}
            >
                <LinkButton title="Contact" route={AppRoutes.CONTACT}/>
                <LinkButton title="Terms" route={AppRoutes.TERMS}/>
                <LinkButton title="Policies" route={AppRoutes.POLICIES}/>
            </Group>

            <Text className={classes.copyright}>© 2023 Vacation Rentals Portal</Text>

            <Group
                spacing={0}
                className={classes.social}
            >

                <CActionImage
                    className={classes.stripe}
                    route={AppRoutes.HOME}
                    light={stripeLight}
                    dark={stripeDark}
                    width={110}
                    height={24}
                    alt="Powered by Stripe"
                />

                <CActionIcon
                    target="_blank"
                    title="Visit the DXB Stay Instagram page"
                    route={AppMetaData.INSTAGRAM}
                    light={<IconBrandInstagram stroke={1} className={classes.instagram}/>}
                    dark={<IconBrandInstagram stroke={1} className={classes.instagram}/>}
                />

            </Group>
        </Container>
    );
};
