import React            from 'react';
import { AppRoutes }    from '@core/router/AppRoutes';
import { CActionImage } from '@core/components/common/CActionImage';

import dxbstayLightLogo from '../../../public/icons/dxbstay-light-logo.svg';
import dxbstayDarkLogo  from '../../../public/icons/dxbstay-dark-logo.svg';


export const AppLogo: React.FC = () => {
    return (
        <CActionImage
            route={AppRoutes.HOME}
            light={dxbstayLightLogo}
            dark={dxbstayDarkLogo}
            width={182}
            height={50}
            alt="DXB Stay - Vacation Rentals Portal"
        />
    );
};
