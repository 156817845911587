import React            from 'react';
import { Container }    from '@mantine/core';
import { createStyles } from '@mantine/styles';
import { AuthMenu }     from '@layouts/Menu/Auth/AuthMenu';
import { UserMenu }     from '@layouts/Menu/User/UserMenu';
import useUserData      from '@features/common/hooks/use-user-data';


const useStyles = createStyles(() => ({
    menuMainDiv: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '100%',
        // maxWidth: 1200,
        marginRight: 0,
        paddingLeft: 0,
        zIndex: 20
    }
}));

// THIS WILL DECIDE WHAT TO SHOW IN MENU
export const AppMenu: React.FC = () => {
    const { classes } = useStyles();

    const { isUser } = useUserData();

    return (
        <Container className={classes.menuMainDiv} pr={6}>

            {isUser() ? <UserMenu/> : <AuthMenu/>}

        </Container>
    );
};
