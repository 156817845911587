import { uniq }  from '@utils/data-utils';
import { empty } from '@utils/index';


export const mapLocationDescLabelText = (_address: string) => {

    if (empty(_address)) return;

    const arrText = _address.split('||');

    if (arrText.length > 1) {
        return arrText[1].trimEnd();
    } else {
        return _address
    }

}

export const mapLocationStreetLabelText = (_address: string) => {

    if (empty(_address)) return;

    const arrText = _address.split('||');

    if (arrText.length > 1) {
        return arrText[0].trimEnd();
    } else {
        return _address
    }

}

export const splitName = (name = '') => {
    const [firstName, ...lastName] = name.split(' ').filter(Boolean);
    return {
        firstName,
        lastName: lastName.join(' '),
    };
};

export const displayAvatarName = (name) => {

    if (name === null || name === undefined) return

    const hasTokens = name.indexOf(' ') !== -1

    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    const check = name.substring(0, hasTokens ? 1 : 2) + (hasTokens ? name.charAt(name.lastIndexOf(' ') + 1) : '');
    return check.toUpperCase();

    /*const nameParts = splitName(name);

    if (nameParts.lastName === '') {
        return name.toUpperCase();
    } else {
        return `${nameParts.firstName[0]}${nameParts.lastName[0]}`.toUpperCase();
    }*/
};

export const showTotal = (total) => {
    return `${total > 1 ? `${total} Records` : `${total} Record`}`;
};

export const genRandomKey = (radix = 36, length = 7) => {
    return Math.random().toString(radix).substring(length);
    //return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5);
};

export const getAddressText = (location) => {
    return `${mapLocationStreetLabelText(location?.address)} \\ ${location?.city} \\ ${location?.country}`;
};

export const getTotalNights = (calendarData) => {
    // YEAH KOE MAZAAQ HORAHA HAY HAN :D :D
    return calendarData?.length;
};

export const getPricePerNight = (totalNights, totalPrice) => {
    return Number(totalPrice) / Number(totalNights);
};

export const getTotalPrice = (calendarData) => {

    const total = calendarData.reduce((acc: number, date) => acc + date.price, 0);

    // console.log(total, "total")

    return total;
};

export function capitalizeFirstLetter(string) {
    return string[0].toUpperCase() + string.slice(1);
}

export function extractLocations(properties) {
    const locations = properties
        ?.map((property) => {
            return {
                propertyId: property.id,
                ...property.location,
            };
        })
        .flat();

    return locations;
}

export function extractLocationAddresses(locations) {
    const addresses = [];

    locations?.forEach((location) => {
        addresses.push(location.address);
    });

    return addresses;
}

export function getPropertiesByLocation(location, properties) {
    // console.log(location, "<>")

    const filtered = properties?.filter((property) => {
        if (property?.location?.address === location) {
            return property;
        } else if (location === 'All') {
            return properties;
        }
    });

    return filtered;
}

export function getPropertiesByKeyword(keyword, properties) {
    // console.log(keyword, "<>")

    const filtered = properties?.filter((property) => {
        if (property?.location.keywords.includes(keyword)) {
            return property;
        } else if (keyword === 'All') {
            return properties;
        }
    });

    return filtered;
}

export function filterCheckPropertiesByIds(propertyID, userProps) {
    // console.log(propertyID, "<propertyID>")
    // console.log(userProps, "<userProps>")

    const filtered = userProps?.filter((property) => {
        return property?.property_id === propertyID;
    });

    // console.log(filtered, "filtered")

    return filtered?.length > 0;
}

export function filterPropertiesByIds(propertyID, userProps) {
    // console.log(propertyID, "<propertyID>")
    // console.log(userProps, "<userProps>")

    const filtered = userProps?.filter((property) => {
        return property?.property_id !== propertyID;
    });

    // console.log(filtered, "filtered")

    return filtered;
}

export function filterUserProperties(allProps, userProps) {
    const filtered = [];

    allProps?.forEach((prop) => {
        userProps?.forEach((uP) => {
            // console.log(uP?.id, "USER PROP")
            // console.log(prop?.id, "PROP")
            // console.log(prop?.id === uP?.id, "===")

            if (prop?.id === uP?.id) {
                filtered.push(prop);
            }
        });
    });

    // console.log(filtered, "FILTERED")

    return filtered?.length > 0 ? filtered : [];
}

export const extractKeywords = (searchLocations) => {

    const arrKeywords = [];

    searchLocations?.forEach((location) => {
        arrKeywords.push(...location.keywords.split(','))
    });

    const cleanKeywords = arrKeywords.map((keyword) => keyword.trim()).flat()

    // return [...new Set(cleanKeywords)];
    return [...uniq(cleanKeywords)];
}

export const extractKeywordsFromSingleLocation = (location) => {

    const arrKeywords = [];

    arrKeywords?.push(...location.keywords.split(','))

    const cleanKeywords = arrKeywords.map((keyword) => keyword.trim()).flat()

    // return [...new Set(cleanKeywords)];
    return [...uniq(cleanKeywords)];
}

/*export function formatTitle(value: number, label: string) {
    return value === 1 ? `${value} ${label}` : `${value} ${label}s`
}*/

export function formatTitle(value: number, label: string) {
    return value === 1 ? `${label}` : `${label}s`
}

export function formatTitleNoS(value: number, label: string) {
    return value >= 1 ? `${label}` : `${label}`
}

export const formatGuestsCount = (count, placeHolder?: string) => {
    if (count !== 0) {
        return count > 1 ? `${count} Guests` : `${count} Guest`
    } else {
        return placeHolder
    }
};

export function mapFavParamToProps(userPropIds, data) {

    return data.map((property) => {

        const _isFavProp = filterCheckPropertiesByIds(property.id, userPropIds)

        return {
            ...property,
            isFavProp: _isFavProp
        }

    });

}

export const getTheKeywords = (listData) => {

    const allKeyWords = []
    const defaultFilters = ['All']

    if (listData !== null) {

        listData?.forEach((prop) => {

            if (prop.location.keywords !== undefined) {
                allKeyWords.push(...extractKeywordsFromSingleLocation(prop.location));
            }
        })

        const orderedLocations = [...uniq(allKeyWords)];

        // ADDING DEFAULTS
        orderedLocations.splice(0, 0, ...defaultFilters);

        orderedLocations?.map((keyword) => {
            return {
                label: keyword,
                value: keyword
            };
        })

        // console.log(orderedLocations, 'orderedLocations')

        return orderedLocations;
    } else {
        return null
    }

}

export const extractTags = (k, g, sd, ed) => {

    /***
     * CODES
     * EN
     *
     * EA
     *
     * EK
     * EG
     * ER
     *
     * EK_EG
     * EK_ER
     * EG_ER
     *
     * */

    // console.log(k, g, sd, ed, '')

    let code = 'EN'

    if (!empty(k) && (!empty(sd) && !empty(ed)) && g !== 0) {
        code = 'EA'
    } else if (!empty(k) && g !== 0) {
        code = 'EK_EG'
    } else if (!empty(k) && (!empty(sd) && !empty(ed))) {
        code = 'EK_ER'
    } else if ((!empty(sd) && !empty(ed)) && g !== 0) {
        code = 'EG_ER'
    } else {
        if (!empty(k)) {
            code = 'EK'
        }
        if (g !== 0) {
            code = 'EG'
        }
        if ((!empty(sd) && !empty(ed))) {
            code = 'ER'
        }
    }

    return code;
}
